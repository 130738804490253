<template>
    <div class="common-body-view">
        <div  class="common-title">
            <img @click="back" src='../../img/left.png' style="width: 20px; height: 20px;">
            <span class="common-title-text">充值</span>
        </div>

        <div class="main">
            <div v-show="step === 1" class="recharge-main">
                <div class="input-view" style="margin-top: 5px;">
                    <van-field label="充值金额" label-width="70" maxlength="5" id="inputAmount"  type="digit" placeholder="请输入充值金额" class="common-input" v-model="amount" />
                </div>

                <div style="font-size: 12px;text-align: left;color: #444444;margin-top: 10px;overflow: hidden;word-break:keep-all;white-space:nowrap;">

                    <span style="color: #888888;">
                        余额<span style="margin-left: 4px;">￥{{qlb}}</span>
                        <span style="margin-left: 10px;">充值后余额</span><span style="margin-left: 4px;">￥{{newQlb}}</span>
                    </span>

                </div>

                <div style="font-size: 12px;text-align: left;color: #888888;margin-top: 10px;">请选择支付方式</div>
                <div style="box-sizing: border-box;margin-top: 8px;">
                    <div style="display: flex;justify-content: space-between">
                        <div class="pay-item" @click="payType = 2" :class="payType === 2 && 'pay-item-active'">
                            <div style="display: flex;align-items: center">
                                <img src="../../img/pay_alipay.png" style="width:17px;height:17px;">
                                <span style="margin-left: 10px;" >支付宝</span>
                            </div>
                            <img v-if="payType === 2" src="../../img/selected_icon.png" style="width: 12px;height: 10px;">
                        </div>
                        <div class="pay-item" @click="payType = 4" :class="payType === 4 && 'pay-item-active'" style="margin-left: 15px;">
                            <div style="display: flex;align-items: center">
                                <img src="../../img/pay_alipay.png" style="width:17px;height:17px;">
                                <span style="margin-left: 10px;" >支付宝扫码</span>
                            </div>
                            <img v-if="payType === 4" src="../../img/selected_icon.png" style="width: 12px;height: 10px;">
                        </div>
                    </div>
                    <div style="display: flex;justify-content: space-between;margin-top: 5px;">
                        <div class="pay-item" @click="payType = 3" :class="payType === 3 && 'pay-item-active'">
                            <div style="display: flex;align-items: center">
                                <img src="../../img/pay_wechat.png" style="width:18px;height:18px;">
                                <span style="margin-left: 10px;">微信</span>
                            </div>
                            <img v-if="payType === 3" src="../../img/selected_icon.png" style="width: 12px;height: 10px;">
                        </div>
                        <div class="pay-item" @click="payType = 5" :class="payType === 5 && 'pay-item-active'" style="margin-left: 15px;">
                            <div style="display: flex;align-items: center">
                                <img src="../../img/pay_wechat.png" style="width:18px;height:18px;">
                                <span style="margin-left: 10px;">微信扫码</span>
                            </div>
                            <img v-if="payType === 5" src="../../img/selected_icon.png" style="width: 12px;height: 10px;">
                        </div>
                    </div>
                </div>

                <div style="margin-top: 20px; display: flex;align-items: center;justify-content: space-between">
                    <div style="width: 120px;font-size: 12px;text-align: left;">
                        需支付 <span style="color: #dc2929">￥</span><span style="font-weight: bold;font-size: 18px;color: #dc2929">{{price}}</span>
                    </div>
                    <van-button type="info"  native-type="submit" :loading="showLoading"
                                style="height: 35px;font-weight: bold;width: 130px;"
                                loading-text="正在提交"  @click="submit"
                                color="#0f78e8">
                        立即支付
                    </van-button>
                </div>

            </div>

            <div v-if="step === 2" style="display:flex;flex-direction: column;align-items: center">
                <div style="color: #666666;font-size: 12px;width: 100%;">
                    请使用{{payType === 4 ? '支付宝' : '微信'}}扫码支付
                </div>
                <div v-if="!paySuccess" class="qr-code" ref="qrCodeUrl" ></div>
                <div v-else style="width: 160px;height: 160px;display: flex;flex-direction: column;align-items: center; margin-top: 10px;box-sizing: border-box">
                    <img src="../../img/success_icon.png" style="width: 70px;height: 70px;margin-top: 25px;">
                    <span style="font-size: 16px;color: #888888;margin-top: 15px;">支付成功</span>
                </div>
                <van-button type="info"  native-type="submit" :loading="showLoading"
                            style="height: 35px;font-weight: bold;width: 130px;margin-top: 10px;"
                            @click="resetPage"
                            color="#cfcfcf">
                    <span style="color: #666666">返回</span>
                </van-button>

            </div>
        </div>

    </div>
</template>

<script>
    import {request} from "@/api/request";
    import Toast from "vant/lib/toast";
    import QRCode from 'qrcodejs2'
    import notice from '@/api/notice';

    export default {
        name: "sdk-recharge",
        components: {
        },
        data() {
            return {
                showLoading: false,
                amount: '',
                payType: '',
                qlb: '0',
                price: '0',
                newQlb: '0',
                step: 1,
                qrCode: {},
                payString: '',
                paySuccess: false,
            }
        },
        watch: {
            amount(value) {
                if (!value) {
                    this.price ='0';
                    this.newQlb = this.qlb;
                }
                else {
                    this.price = parseInt(value);
                    this.newQlb = this.price + parseFloat(this.qlb);
                }

            },
        },
        mounted () {
            this.addInputBlur();
            this.getQlb();
        },
        methods: {
            getQlb() {
                let options = {
                    url:"/zfapp/getUserBalanceList",
                    data: {}
                };
                request(options).then(res => {
                    if (res.data.success) {
                        this.qlb = parseFloat(res.data.data.qlBalance);
                    }
                    else {
                        Toast(res.data.error.message);
                    }
                });
            },
            resetPage() {
                this.step = 1;
                this.amount = '';
                this.payType = '';
            },
            submit() {
                if (!this.amount) {
                    Toast('请输入充值金额');
                    return;
                }
                if (!this.payType) {
                    Toast('请选择支付方式');
                    return;
                }

                this.showLoading = true;
                let options = {
                    url:"/zfapp/qlOrder",
                    data: {
                        money: this.amount,
                        paymentType: this.payType
                    }
                };

                request(options).then(res => {
                    this.showLoading = false;
                    if (res.data.success) {
                        this.amount = '';
                        this.payString = res.data.data.payString;
                        if (this.payType === 4 || this.payType === 5) {
                            this.step = 2;
                            this.$nextTick(function() {
                                this.creatQrCode();
                            })
                        }
                        else {
                            notice.openUrl(this.payString);
                        }
                    }
                    else {
                        Toast(res.data.error.message);
                    }
                });
            },
            back() {
                this.$parent.$parent.closeRechargePage();
            },
            addInputBlur() {
                let isIOS = !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) // 检测是否为ios
                if(!isIOS) {
                    console.log('不是iOS，取消blur事件');
                    return ;
                }
                let t = document.getElementById('inputAmount');
                t.removeEventListener('blur', function(){ window.scrollTo(0,0)});
                t.addEventListener('blur', function(){window.scrollTo(0,0)}, false);
            },
            creatQrCode() {
                //console.log(this.$refs.qrCodeUrl.innerHTML = "");
                this.$refs.qrCodeUrl.innerHTML = ""
                if (this.qrCode._oQRCode) {
                    this.qrCode.clear(); //清除
                }
                this.qrCode = new QRCode(this.$refs.qrCodeUrl, {
                    text: this.payString,
                    width: 160,
                    height: 160,
                    colorDark: '#000000',
                    colorLight: '#ffffff',
                    correctLevel: 3

                });
            }
        }
    }
</script>

<style scoped>

    .recharge-main{
        width: 100%;
        display: flex;
        flex-direction: column;
        color: #333333;
    }

    .pay-item {
        display: flex;
        justify-content: space-between;
        font-size: 12px;
        padding: 0 10px;
        align-items: center;
        height: 33px;
        color: #666666;
        outline: 1px solid #e0e0e0;
        background: #ffffff;
        flex: 1;
        box-shadow: 2px 2px 10px 3px rgba(0,0,0,.1);
    }
    .pay-item-active {
        outline: 1px solid #dc2929;
        color: #dc2929;
        font-weight: bold;
    }

    .van-cell {
        padding: 7px 10px;
    }
    /deep/ .van-field__control {
        color: #333333;
    }
    .van-cell::after {
        border-bottom: none;
    }

    .qr-code{
        /*实现垂直居中*/
        display: inline-block;
        align-items: center;
        justify-content: center;
        text-align: center;
        margin-top: 10px;
    }
    .qr-code img{
        /*实现垂直居中*/
        align-items: center;
        justify-content: left;
        margin: 0 auto;
        display: unset !important;
        width: 190px;
        height: 190px;
    }
</style>