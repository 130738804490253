import axios from 'axios'
import SERVERS from '@/api/servers.js'
import {Toast} from "vant";

axios.defaults.baseURL = SERVERS.BASEURL

// http request 拦截器
axios.interceptors.request.use(
    config => {
        config.headers['token'] = localStorage.getItem('token')
        return config
    },
    err => {
        return Promise.reject(err)
    }
)

// 返回状态判断
axios.interceptors.response.use(
    (response) => {
        // 登录token失效
        //console.log('response', response.data)
        if (response.data.error && response.data.error.code === '911') {
            localStorage.removeItem('userInfo');
            localStorage.removeItem('token');
            localStorage.removeItem('userId');
            localStorage.removeItem('speedRate');
            localStorage.setItem('login_status', 'logout');
            window.location.href = '#/login';
            Toast('帐号在其他地方登录，您已退出游戏');
            //window.location.reload()
        }
        return response
    },
    (error) => {
        return error
    }
)
export default axios
