<template>
    <div>
        <div style="text-align: left;padding: 8px;font-size: 24px;">
            <span @click="selectEmoji(e)" v-for="(e, i) in emojiList">{{e}}</span>
        </div>
    </div>
</template>

<script>
    export default {
        name: "myemoji",
        data(){
            return {
                /*emojiList: [
                    '😀',
                    '😃',
                    '😄',
                    '😁',
                    '😆',
                    '😅',
                    '🤣',
                    '😂',
                    '🙂',
                    '🙃',
                    '😉',
                    '😊',
                    '😇',
                    '🥰',
                    '😍',
                    '🤩',
                    '😘',
                    '😗',
                    '😚',
                    '😙',
                    '🥲',
                    '😋',
                    '😛',
                    '😜',
                    '🤪',
                    '😝',
                    '🤑',
                    '🤗',
                    '🤭',
                    '🤫',
                    '🤔',
                    '🤐',
                    '🤨',
                    '😐',
                    '😑',
                    '😶',
                    '😏',
                    '😒',
                    '🙄',
                    '😬',
                    '🤥',
                    '😌',
                    '😔',
                    '😪',
                    '🤤',
                    '😴',
                    '😷',
                    '🤒',
                    '🤕',
                    '🤢',
                    '🤮',
                    '🤧',
                    '🥵',
                    '🥶',
                    '🥴',
                    '😵',
                    '🤯',
                    '🤠',
                    '🥳',
                    '🥸',
                    '😎',
                    '🤓',
                    '🧐',
                    '😕',
                    '😟',
                    '🙁',
                    '😮',
                    '😯',
                    '😲',
                    '😳',
                    '🥺',
                    '😦',
                    '😧',
                    '😨',
                    '😰',
                    '😥',
                    '😢',
                    '😭',
                    '😱',
                    '😖',
                    '😣',
                    '😞',
                    '😓',
                    '😩',
                    '😫',
                    '🥱',
                    '😤',
                    '😡',
                    '😠',
                    '🤬'
                ],*/
                emojiList: [
                    '😀',
                    '😁',
                    '😂',
                    '😃',
                    '😄',
                    '😅',
                    '😆',
                    '😉',
                    '😊',
                    '😋',
                    '😎',
                    '😍',
                    '😘',
                    '😗',
                    '😙',
                    '😚',
                    '😇',
                    '😐',
                    '😑',
                    '😶',
                    '😏',
                    '😣',
                    '😥',
                    '😮',
                    '😯',
                    '😪',
                    '😫',
                    '😴',
                    '😌',
                    '😛',
                    '😜',
                    '😝',
                    '😒',
                    '😓',
                    '😔',
                    '😕',
                    '😲',
                    '😷',
                    '😖',
                    '😞',
                    '😟',
                    '😤',
                    '😢',
                    '😭',
                    '😦',
                    '😧',
                    '😨',
                    '😬',
                    '😰',
                    '😱',
                    '😳',
                    '😵',
                    '😡',
                    '😠',
                    '😈',
                    '👿',
                    '👹',
                    '👺',
                    '💀',
                    '👻',
                    '👽',
                    '👦',
                    '👧',
                    '👨',
                    '👩',
                    '👴',
                    '👵',
                    '👶',
                    '👱',
                    '👮',
                    '👲',
                    '👳',
                    '👷',
                    '👸',
                    '💂',
                    '🎅',
                    '👰',
                    '👼',
                    '💆',
                    '💇',
                    '🙍',
                    '🙎',
                    '🙅',
                    '🙆',
                    '💁',
                    '🙋',
                    '🙇',
                    '🙌',
                    '🙏',
                    '👤',
                    '👥',
                    '🚶',
                    '🏃',
                    '👯',
                    '💃',
                    '👫',
                    '👬',
                    '👭',
                    '💏',
                    '💑',
                    '👪',
                    '💪',
                    '👈',
                    '👉',
                    '☝',
                    '👆',
                    '👇',
                    '✌',
                    '✋',
                    '👌',
                    '👍',
                    '👎',
                    '✊',
                    '👊',
                    '👋',
                    '👏',
                    '👐',
                    '✍',
                    '👣',
                    '👀',
                    '👂',
                    '👃',
                    '👅',
                    '👄',
                    '💋',
                    '👓',
                    '👔',
                    '👕',
                    '👖',
                    '👗',
                    '👘',
                    '👙',
                    '👚',
                    '👛',
                    '👜',
                    '👝',
                    '🎒',
                    '💼',
                    '👞',
                    '👟',
                    '👠',
                    '👡',
                    '👢',
                    '👑',
                    '👒',
                    '🎩',
                    '🎓',
                    '💄',
                    '💅',
                    '💍',
                    '🌂'
                ]
            }
        },
        methods: {
            selectEmoji(e){
                this.$emit("selectEmoji", e)
            }
        }
    }
</script>

<style scoped>

</style>