let from;

export default {
    openUrl(url) {
        this.initFrom();
        if (from === 'tsjxf_android') {
            window.tsJsInterface.openUrl(url)
        }
        else {//网页
            window.open(url, '_blank');
        }

    },
    jumpApp(url){
        this.initFrom();
        if (from === 'tsjxf_android') {
            window.tsJsInterface.startIntent(url)
        }
        else {
            window.open(url, '_blank');
        }
    },
    copy(content){
        this.initFrom();
        if (from === 'tsjxf_android') {
            window.tsJsInterface.copyString(content)
        }
        else {
            const input = document.createElement('input')
            document.body.appendChild(input)
            input.setAttribute('value', content)
            input.select()
            if (document.execCommand('copy')) {
                document.execCommand('copy')
            }
            document.body.removeChild(input)
        }
    },
    initFrom() {
        if (!from) {
            from = localStorage.getItem('from')
        }
    }


}