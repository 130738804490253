<template>
    <div class="center">
        <div style="margin-left: -20px;display: flex;align-items: center">
            <van-icon @click="back" name="arrow-left" size="20"/>
            <span style="font-size: 16px;margin-left: 10px;">解绑手机</span>
        </div>
        <div style="margin: 20px 0;background-color: #FFFAEF; border-radius: 5px ;padding: 8px;">
            <span style="font-size: 12px;color:#FBB610;">如果当前手机号码不再使用，请及时解绑并绑定新手机号码</span>
        </div>
        <div class="menu-view">
            <van-field class="common-input"  maxlength="11" v-model="tel" readonly clearable placeholder="请输入手机号码"/>

            <van-field class="common-input" type="number" maxlength="6" placeholder="请输入验证码" v-model="codeValue" >
                <template #button>
                    <van-count-down
                            v-show="showCountDown"
                            ref="countDown"
                            :time="60000"
                            :auto-start="false"
                            format="重新发送(ss秒)"
                            style="font-size: 13px;color: #888888"
                            @finish="finish"
                    />
                    <span style="color: #5A6EFC;font-size: 13px;" v-show="!showCountDown" @click="sendCode">发送验证码</span>
                </template>
            </van-field>

        </div>
        <van-button color="#5A6EFC" class="main-button margin-10" block @click="submit">确认解绑</van-button>

        <myloading v-show="showLoading"></myloading>

    </div>
</template>

<script>
    import {request} from '@/api/request'
    import {Toast} from 'vant'
    import myloading from '@/components/myloading'

    export default {
        name: "bindtel",
        components: {
            myloading
        },
        data() {
            return {
                showLoading: false,
                codeValue: '',
                tel: '',
                telReg : /^1\d{10}$/,
                codeReg : /^\d{6}$/,
                userInfo : {},
                showCountDown: false,
            }
        },
        mounted() {
            this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
            this.tel = this.userInfo.phone;
        },
        methods: {
            back() {
                this.$parent.$parent.closeUnBindPage();
            },
            submit() {
                if (!this.codeReg.test(this.codeValue)) {
                    this.topToast('请输入6位数字验证码');
                    return ;
                }

                this.showLoading = true;
                let options = {
                    url:"/zfapp/unBindPhone",
                    data: {
                        checkCode: this.codeValue
                    }
                };
                request(options).then(res => {
                    this.showLoading = false;
                    if (res.data.success) {
                        this.topToast('手机号码解绑成功');
                        this.userInfo.phone = null;
                        localStorage.setItem('userInfo', JSON.stringify(this.userInfo));
                        this.back();
                        this.tel = '';
                        this.codeValue = '';
                    }
                    else {
                        this.topToast(res.data.error.message);
                    }
                });

            },
            sendCode() {
                this.showLoading = true;
                let options = {
                    url:"/zfapp/sendSms",
                    data: {
                        type: 3
                    }
                };
                request(options).then(res => {
                    this.showLoading = false;
                    if (res.data.success) {
                        this.showCountDown = true;
                        this.$refs.countDown.start();
                        this.topToast('验证码发送成功');
                    }
                    else {
                        this.topToast(res.data.error.message);
                    }
                });
            },
            finish() {
                this.showCountDown = false;
                this.$refs.countDown.reset();
            },
            topToast(m) {
                Toast({
                    message: m,
                    position: 'top',
                });
            }
        }
    }
</script>

<style scoped>
    .center {
        flex: 1;
        flex-direction: column;
        background-color: #F5F6F8;
        height: 100%;
        box-sizing: border-box;
        padding: 60px 40px 0;
        text-align: left;
    }
    .title-tip{
        font-size: 25px;
        margin-top: 10px;
        font-weight: 700;
    }
    .menu-view {
        margin: 30px auto 0;
        border-radius: 5px;
        background-color: #FFFFFF;
    }
    .main-button{
        font-size: 16px;
        height: 40px;
        border-radius: 5px;
        margin-top: 40px;
    }
    .common-input{
        background: transparent;
        height: 50px;
    }

</style>