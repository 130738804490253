<template>
    <div ref="gc_main" style="overflow: hidden;height: 100%">
<!--        <img src="../../static/close.png" width="30px" height="30px" style="position: absolute;left: 18px;top: 65px" @click="back"/>-->
        <button class="left-button" @click="back">返回游戏</button>
    </div>
</template>

<script>
export default {
    name: "TempGame",
    data(){
        return {
            gcIframe: null,
        }
    },
    mounted() {
        // this.createIframe();
    },
    methods: {
        back() {
            this.$parent.$parent.closeGcGame();
            this.gcIframe.src = "about:blank";
            this.$refs.gc_main.removeChild(this.gcIframe);
            this.gcIframe = null;
        },
        createIframe(){
            let iframe = document.createElement("iframe");
            iframe.style.overflow = 'hidden';
            iframe.style.border = 'none';
            iframe.style.padding = '0';
            iframe.style.margin = '0';
            iframe.frameBorder = '0';
            iframe.scrolling = 'auto';
            iframe.src = "https://m.7li.club?token=" + localStorage.getItem("token");
            iframe.id = "gc_iframe"
            iframe.onload = () => this.adjustIframe();
            this.$refs.gc_main.appendChild(iframe);

            this.gcIframe = iframe;
            return iframe;
        },
        adjustIframe(){
            this.gcIframe.width = document.documentElement.clientWidth.toString();
            this.gcIframe.height = document.documentElement.clientHeight.toString();
        }
    }
}
</script>

<style scoped>
.left-button{
    width: 25px;
    height: 80px;
    position: absolute;
    left:0;
    top: 80px;
    font-size: 12px;
    padding: 0;
    color: #fff;
    background-color: #fdb758;
    border: 1px solid #fdb758;
}
</style>