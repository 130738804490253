import Vue from 'vue'
import App from './App.vue'
import router from './router'
import 'vant/lib/index.css';
import './common/common.css'
import SlideVerify from 'vue-monoplasty-slide-verify';
Vue.use(SlideVerify)

import {
    Button,
    Icon,
    Image as VanImage,
    Divider,
    Tag,
    List,
    Cell,
    CellGroup,
    Dialog,
    Toast,
    Picker,
    Popup,
    Field,
    Form,
    CountDown,
    Loading,
    Checkbox,
    RadioGroup,
    Radio,
    Overlay,
    Empty,
    NoticeBar,
} from 'vant';

Vue.use(Button)
Vue.use(Icon)
Vue.use(VanImage)
Vue.use(Divider);
Vue.use(Tag);
Vue.use(List);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Dialog);
Vue.use(Toast);
Vue.use(Picker);
Vue.use(Popup);
Vue.use(Field);
Vue.use(Form);
Vue.use(CountDown);
Vue.use(Loading);
Vue.use(Checkbox);
Vue.use(RadioGroup);
Vue.use(Radio);
Vue.use(Overlay);
Vue.use(Empty);
Vue.use(NoticeBar);

Vue.config.productionTip = false

router.beforeEach(( to, from, next ) => {
    /*if (to.name !== 'login') {
      let token = localStorage.getItem('token');
      console.log('token', token);
      if (!token) {
        window.location.href = '#/login'
        window.location.reload()
      } else {
        localStorage.setItem('beforeUrl', from.fullPath);
        next();
      }
    } else {
      next();
    }*/
    if (to.meta.title) {
        document.title = to.meta.title;
    }
    next();
});

router.afterEach(() => {
    // NProgress.done()
})


new Vue({
    render: h => h(App),
    router,
}).$mount('#app')
