import Vue from 'vue'
import Router from 'vue-router'

import Login from './page/login/login';
import Register from './page/login/register';
import Forget from './page/login/forget';
import Game from './page/game/game';
import Temp from './page/temp/temp';
import JxfGame from './page/jxf/JxfGame';
import iosGame from './page/jxf/iosGame';
//import Test from './page/jxf/test';

Vue.use(Router)

export default new Router({
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            redirect: '/sq/1/206'
        },
        {
            path: '/sq/*/*',
            name: 'Temp',
            component: Temp
        },
        {
            path: '/login',
            name: 'Login',
            component: Login
        },
        {
            path: '/register',
            name: 'Register',
            component: Register
        },
        {
            path: '/forget',
            name: 'Forget',
            component: Forget
        },
        {
            path: '/game',
            name: 'game',
            component: Game
        },
        {
            path: '/ts/jxf',
            name: 'JxfGame',
            component: JxfGame
        },
        {
            path: '/jxfios/*/*',
            name: 'iosGame',
            component: iosGame
        },

    ]
})