<template>
    <div class="pay-view">
        <div style="width: 100%;height: 100%;background-color: #F7F8FA;border-radius: 8px;padding: 0 15px 5px;box-sizing: border-box">
            <div style="font-size: 18px;font-weight: bold;color: #333333;padding: 10px 0;">
                {{goodsName}}
            </div>
            <div style="width: 100%;padding: 0 9px;box-sizing: border-box;background-color: #FFFFFF">
                <div style="display: flex;justify-content: space-between;font-size: 13px;padding: 6px 0;">
                    <span>商品价格</span>
                    <span>{{orderMoney}}</span>
                </div>
                <div style="display: flex;justify-content: space-between;font-size: 13px;padding: 6px 0;" :style="showDiscount ? '' : 'color : #aaaaaa'">
                    <span>优惠折扣 / 抵扣率</span>
                    <span>{{preferenceMoney}}</span>
                </div>
                <div v-if="showNotBindWxDiscountMsg" style="font-size: 12px;text-align: left;color: #e5260c">
                    绑定微信，本区当天充值均享受<span style="font-weight: bold;">2.5</span>折优惠，
                    <van-count-down style="display: inline;color: #e33b08;font-size: 12px;"
                            ref="countDown"
                            :time="leaveTime"
                            :auto-start="true"
                            @finish="finish"/>
                    内有效。 <span style="color: #0f78e8;font-weight: bold;" @click="openBindWeixinPage">去绑定微信</span>
                </div>
                <div v-if="showBindWxDiscountMsg" style="font-size: 12px;text-align: left;color: #04b816">
                    已享受2.5折优惠，
                    <van-count-down  style="display: inline;color: #04b816;font-size: 12px;"
                            ref="countDown"
                            :time="leaveTime"
                            :auto-start="true"
                            @finish="finish"/>
                    内有效。
                </div>

            </div>
            <van-divider  :style="{ color: '#888888', borderColor: '#888888',margin: '5px 0',fontSize: '12px' }">请选择支付方式</van-divider>
            <div style="width: 100%;padding: 0 9px;box-sizing: border-box;background-color: #FFFFFF">
                <van-radio-group v-model="payType">
                    <div @click="payType = '2'" style="display: flex;justify-content: space-between;font-size: 13px;padding: 6px 0;cursor:pointer;">
                        <div style="display: flex;align-items: center">
                            <img src="../../static/zfb.png" style="width: 20px;height: 20px;">
                            <span style="margin-left: 10px;" >支付宝</span>
                        </div>
                        <van-radio name="2" shape="square" icon-size="14px"></van-radio>
                    </div>
                    <div class="item-line"></div>
                    <div @click="payType = '3'" style="display: flex;justify-content: space-between;font-size: 13px;padding: 6px 0;cursor:pointer;">
                        <div style="display: flex;align-items: center">
                            <img src="../../static/wx.png" style="width: 20px;height: 20px;">
                            <span style="margin-left: 10px;">微信</span>
                        </div>
                        <van-radio name="3" shape="square" icon-size="14px"></van-radio>
                    </div>
                    <div class="item-line"></div>
                    <div @click="payType = '1'" style="display: flex;justify-content: space-between;font-size: 13px;padding: 6px 0;cursor:pointer;">
                        <div style="display: flex;align-items: center">
                            <img src="../../static/main-round.png" style="width: 20px;height: 20px;">
                            <span style="margin-left: 10px;">七里币<span style="font-size: 12px;color: #888888">（余额 {{qlb}} ）</span></span>
                        </div>
                        <van-radio name="1" shape="square" icon-size="14px"></van-radio>
                    </div>
                </van-radio-group>
            </div>
            <van-button color="#0f78e8" class="pay-button" :loading="payLoading" block @click="pay">立即支付</van-button>
            <div style="color: #888888;font-size: 12px;padding: 6px 0;margin-top: 5px;cursor:pointer;user-select: none;">
                <span @click="closePayPage">取消支付</span>
            </div>

            <!-- <iframe v-if="payIframeShow" :src="payUrl"  width="90%" frameborder="no" border="0" marginwidth="0"
                     marginheight="0" scrolling="yes" height="1px;" style="bottom: 2px;left: 5%"></iframe>
-->
        </div>
    </div>
</template>

<script>
    import '../../css/commonpage.css'
    import notice from '@/api/notice';
    import {Toast} from "vant";
    import {request} from "@/api/request";

    export default {
        name: "jxf-trade",
        components: {
        },
        data() {
            return {
                showLoading: false,
                payLoading: false,
                goodsName: '',
                orderMoney: '',
                showDiscount: false,
                preferenceMoney: '',
                uid: '',
                payType: '',
                qlb: '0',
                order: {},
                showBindWxDiscountMsg: false,
                showNotBindWxDiscountMsg: false,
                leaveTime: 0,
            }
        },
        mounted() {
        },
        methods: {
            closePayPage() {
                this.$parent.$parent.closePayPage();
            },
            finish() {
                this.showBindWxDiscountMsg = false;
                this.showNotBindWxDiscountMsg = false;
            },
            openBindWeixinPage() {
                this.$parent.$parent.openBindWeixinPageFromPay();
            },
            initOrder(order) {
                this.goodsName = order.goodsName;
                this.orderMoney = "￥" + (order.money / 100).toFixed(2);
                this.money = order.money;
                this.order = order;

                this.showLoading = true;
                let options = {
                    url:"/zfapp/checkUserForTSJX",
                    data: {
                        cpOrder: order.cpOrderId,
                        goodsName: this.goodsName,
                        money: this.money,
                        serviceId: order.server,
                        desc: this.goodsName,
                        roleName: order.role,
                    }
                };
                request(options).then(res => {
                    this.showLoading = false;
                    if (res.data.success) {
                        if (res.data.data.discount !== "1") {
                            this.showDiscount = true;
                            this.preferenceMoney = "- ￥" + res.data.data.preferenceMoney + ' / ' + res.data.data
                                .discount;
                        } else {
                            this.showDiscount = false;
                            this.preferenceMoney = "无"
                        }
                        this.orderMoney = "￥" + res.data.data.orderMoney;
                        this.leaveTime =  parseInt(res.data.data.leaveTime) * 1000;
                        let bindWxStatus = res.data.data.bindWxStatus;
                        if (this.leaveTime !== '0') {//当前有2.5折活动
                            if (bindWxStatus === '0') {
                                this.showNotBindWxDiscountMsg = true;
                            }
                            else {
                                this.showBindWxDiscountMsg = true;
                            }
                        }

                    }
                    else {
                        Toast(res.data.error.message);
                    }
                });
                this.getBalance();
            },
            getBalance() {
                let getQlbOptions = {
                    url:"/zfapp/getGameQLBalance",
                    data: {}
                };
                request(getQlbOptions).then(res => {
                    if (res.data.success) {
                        this.qlb = res.data.data;
                    }
                    else {
                        Toast(res.data.error.message);
                    }
                });
            },
            pay() {
                if (!this.payType) {
                    Toast('请选择支付方式');
                    return ;
                }
                this.payLoading = true;

                let options = {
                    url:"/zfapp/preOrderForTSJX",
                    data: {
                        cpOrder: this.order.cpOrderId,
                        goodsName: this.goodsName,
                        money: this.money,
                        paymentType: this.payType,
                        serviceId: this.order.server,
                        desc: this.goodsName,
                        roleName: this.order.role,
                        roleId: this.order.role,
                        orderTime: this.order.time,
                        sign: this.order.sign
                    }
                };
                request(options).then(res => {
                    this.payLoading = false;
                    if (res.data.success) {
                        if (res.data.data.paymentType !== "1") {//如果是七里币支付的话就不需要打开链接了
                            let from = localStorage.getItem('from');
                            //处理ios端七里app的支付
                            if (from === 'qiliApp') {
                                uni.postMessage({
                                    data: {
                                        action: 'continue',
                                        msg: res.data.data.payString
                                    }
                                });
                                setTimeout(() => {
                                    this.closePayPage();
                                }, 500)
                            }
                            else if (from === 'tsjxf_ios') {
                                this.closePayPage();
                                this.$parent.$parent.openIOSPayConfirm(res.data.data.payString);
                            }
                            else {
                                notice.openUrl(res.data.data.payString);
                                setTimeout(() => {
                                    this.closePayPage();
                                }, 500)
                            }
                        }
                        else {
                            this.closePayPage();
                            Toast('支付成功');
                        }
                    }
                    else {
                        this.closePayPage();
                        Toast(res.data.error.message);
                    }
                });

            },
            openWx() {
                this.showLoading = true;
                setTimeout(() => {
                    this.showLoading = false;
                }, 2000);
                let url = 'weixin://';
                notice.jumpApp(url);
            }
        }
    }
</script>

<style scoped>
    .pay-button{
        font-size: 12px;
        height: 30px;
        line-height: 30px;
        border-radius: 2px;
        width: 150px;
        margin: 12px auto 0;
        font-weight: bold;
    }
    .item-line {
        height: 1px;
        background-color: #eeeeee;
    }
</style>