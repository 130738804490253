<template>
    <div class="login-main">

        <div style="display: flex;align-items: center;width: 100%;background-color: #6e8ec9;height: 80px;">
            <img src="../../static/main-round.png" class="logo" style="width: 50px;height: 50px;">
            <span style="font-size: 22px;font-weight: bold;color: #ffffff;margin-left: 20px;">七里网络</span>
        </div>

        <div class="form-view">
            <div class="logo-view">
                <span class="top-span">找回密码</span>
            </div>
            <div class="ts-form">
                <van-field placeholder="七里帐号" @focus="inputOnFocus(1)" @blur="inputBlur(1)" :style="usernameInputStyle" v-model="username" clearable></van-field>

                <van-field class="margin-5"  @focus="inputOnFocus(2)" @blur="inputBlur(2)" :style="passwordInputStyle" type="password"  placeholder="请输入新密码" v-model="password" clearable></van-field>

                <van-field class="margin-5"  @focus="inputOnFocus(3)" @blur="inputBlur(3)" :style="password2InputStyle" type="password"  placeholder="请再次输入密码" v-model="password2" clearable></van-field>

                <van-field class="margin-5" @focus="inputOnFocus(4)" @blur="inputBlur(4)" :style="codeInputStyle" v-model="code" type="number" clearable
                        placeholder="请输入验证码">
                    <template #button>
                        <van-count-down
                                v-show="showCountDown"
                                ref="countDown"
                                :time="60000"
                                :auto-start="false"
                                format="重新发送(ss秒)"
                                style="font-size: 13px;color: #888888"
                                @finish="finish"
                        />
                        <span  style="color: #5A6EFC;font-size: 13px;" v-show="!showCountDown" @click="sendCode">发送验证码</span>
                    </template>
                </van-field>

                <van-button color="#5A6EFC" class="main-button margin-10" block @click="submit">确认</van-button>

                <div class="margin-10" style="display: flex;justify-content: flex-end">
                    <div style="color: #999999; font-size: 13px;">
                        <span style="color: #5A6EFC; margin-left: 6px;" @click="back">返回登录页面</span>
                    </div>

                </div>
            </div>
        </div>

        <myloading v-show="showLoading"></myloading>
    </div>
</template>

<script>
    import {request} from '@/api/request'
    import crypto from '@/utils/crypto.js';
    import {Toast} from 'vant'
    import myloading from '@/components/myloading'

    export default {
        name: "login",
        components: {
            myloading
        },
        data() {
            return {
                showLoading: false,
                focusInput: 'outline: 2px #3478F6 solid;',
                usernameInputStyle: '',
                passwordInputStyle: '',
                password2InputStyle: '',
                codeInputStyle: '',

                username: '',
                password: '',
                password2: '',
                code: '',
                showCountDown: false,

                usernameRegexp: /^[a-zA-Z0-9_]{6,12}$/,
                passwordRegexp: /^[a-zA-Z0-9~!@#$%^&*()_+.-=]{6,12}$/,
                phoneNumberRegexp: /^1\d{10}$/,
                codeRegexp: /^\d{6}$/,
                sendCtrl: true,
                show: true,
            }
        },
        methods: {
            inputOnFocus(e) {
                if (e == 1) {
                    this.usernameInputStyle = this.focusInput;
                }
                else if (e == 2) {
                    this.passwordInputStyle = this.focusInput;
                }
                else if (e == 3) {
                    this.password2InputStyle = this.focusInput;
                }
                else if (e == 4) {
                    this.codeInputStyle = this.focusInput;
                }
            },
            inputBlur(e) {
                if (e == 1) {
                    this.usernameInputStyle = '';
                }
                else if (e == 2) {
                    this.passwordInputStyle = '';
                }
                else if (e == 3) {
                    this.password2InputStyle = '';
                }
                else if (e == 4) {
                    this.codeInputStyle = '';
                }
            },
            back() {
                this.$router.back();
            },
            submit() {
                let ok = this.checkForm();
                if (ok !== 'ok') {
                    Toast(ok);
                    return ;
                }
                if (!this.codeRegexp.test(this.code)) {
                    Toast("验证码为6位数字");
                    return ;
                }
                this.showLoading = true;
                let options = {
                    url:"/zfapp/modifyPasswordByPhone",
                    data: {
                        userName: this.username,
                        newUserPwd: crypto.encrypt(this.password),
                        checkCode: this.code
                    }
                };
                request(options).then(res => {
                    this.showLoading = false;
                    console.log(res);
                    if (res.data.success) {
                        Toast('密码找回成功');
                        setTimeout(() => {
                            this.back();
                        }, 2000);
                    }
                    else {
                        Toast(res.data.error.message);
                    }
                });

            },
            sendCode() {
                let ok = this.checkForm();
                if (ok !== 'ok') {
                    Toast(ok);
                    return ;
                }
                this.showLoading = true;
                let options = {
                    url:"/zfapp/sendSms",
                    data: {
                        type: 5,
                        phone: this.username
                    }
                };
                request(options).then(res => {
                    console.log(res);
                    this.showLoading = false;
                    if (res.data.success) {
                        this.showCountDown = true;
                        this.$refs.countDown.start();
                        Toast('验证码发送成功');
                    }
                    else {
                        Toast(res.data.error.message);
                    }
                });
            },
            finish() {
                this.showCountDown = false;
                this.$refs.countDown.reset();
            },
            checkForm() {
                if (!this.username) {
                    return "请输入七里帐号";
                }
                if (!this.usernameRegexp.test(this.username)) {
                    return "七里帐号为6-12位数字字母";
                }
                if (!this.password) {
                    return "请输入密码";
                }
                if (!this.passwordRegexp.test(this.password)) {
                    return "密码为6-12位数字字母特殊符号";
                }
                if (!this.password2) {
                    return "请再次输入密码";
                }
                if (this.password !== this.password2) {
                    return "两次密码输入不一致，请重新输入";
                }
                return "ok";
            },
        }
    }
</script>

<style scoped>
    .login-main {
        width: 100vw;
        height: 100vh;
        background-color: #f1f4f6;
        overflow: hidden;
    }

    .form-view{
        box-sizing: border-box;
        width: 90%;
        margin: 120px auto 0;
        max-width: 400px;
        min-width: 300px;
        background-color: rgba(255,255,255,0.9);
        border-radius: 5px;
        min-height: 20%;
        padding: 20px 27px 0;
    }
    .top-span{
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 10px;
    }
    .ts-form{
        padding-top: 10px;
        padding-bottom: 20px;
    }
    .logo {
        margin-left: 30px;
    }
    .logo-view{
        display: flex;
        align-items: flex-end;
    }
    .main-button{
        font-size: 16px;
        height: 40px;
    }

    .van-field{
        outline: 1px #dbdbdb solid;
        border-radius: 3px;
    }
    .van-field__control:focus{
        outline: 1px red solid !important;
        border-radius: 3px;
    }
</style>