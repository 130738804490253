<template>
    <div class="common-body-view">
        <div  class="common-title">
            <img @click="back" src='../../img/left.png' style="width: 20px; height: 20px;">
            <span class="common-title-text">游戏喊话</span>
        </div>

        <div style="position: absolute;top : 10px;right: 20px;">
            <span style="margin-left: 10px;font-size: 12px;color: #888888;">
                当前余额<span style="color: #333333;font-family: myFont,serif;margin-left: 10px;">{{qlb}}</span>
            </span>
        </div>

        <div class="main" style="padding: 0;">
            <div class="body" v-show="!showEmoji"  id="msgListView">
                <div style="padding-bottom: 5px;font-size: 13px;">
                    <div v-for="(item,i) in messageList">

                        <div v-if="item.userId !== userId" style="display: flex;margin-top: 10px;">
                        <!--<div v-if="i % 2 === 0" style="display: flex;margin-top: 10px;margin-bottom: 10px;">-->
                            <div style="width: 30px;height: 30px;margin-top: 3px;">
                                <img v-if="!!item.imgUrl" :src="item.imgUrl" style="width: 30px;height: 30px;border-radius: 3px;"/>
                                <img v-else src="../../img/default_img.png" style="width: 30px;height: 30px;border-radius: 3px;"/>
                            </div>
                            <div style="text-align: left;margin-left: 10px;flex: 1">
                                <div style="color: #888888;font-size: 10px;word-break:break-all;white-space:normal;overflow: hidden;-webkit-line-clamp: 1; display: -webkit-box;-webkit-box-orient: vertical;">
                                    <span>{{item.serverName}} {{item.roleName}}</span>
                                    <span style="margin-left: 10px">{{item.time}}</span>
                                </div>
                                <div style="background-color: #eeeeee;padding: 8px 10px;margin-top: 5px;margin-right:20px; border-radius: 5px;word-break:break-all;word-wrap: break-word;width: fit-content">
                                    {{item.msg}}
                                </div>
                            </div>
                        </div>

                        <div v-else style="display: flex;margin-top: 10px;justify-content: flex-end;">
                            <div style="text-align: right;margin-right: 10px;margin-left: 50px;display: flex;flex-direction: column;align-items: flex-end">
                                <div style="color: #888888;font-size: 10px;"><span style="margin-right: 5px">{{item.time}}</span>我</div>
                                <div style="background-color: #9fe05d;padding: 8px 10px;margin-top: 5px;text-align: left;border-radius: 5px;word-break:break-all;word-wrap: break-word;width: fit-content">
                                    {{item.msg}}
                                </div>
                            </div>
                            <div style="width: 30px;height: 30px;margin-top: 3px;">
                                <img v-if="!!item.imgUrl" :src="item.imgUrl" style="width: 30px;height: 30px;border-radius: 3px;"/>
                                <img v-else src="../../img/default_img.png" style="width: 30px;height: 30px;border-radius: 3px;"/>
                            </div>
                        </div>
                    </div>

                    <div v-if="messageList.length === 0" style="color: #999999;font-size: 15px;margin-top: 20px;">
                        暂无聊天信息
                    </div>

                </div>

            </div>

            <div style="width: 100%; height: 40px; display: flex;padding: 0;font-size: 12px;align-items: center;background-color: #f0f0f0">
                <van-field maxlength="50" @focus="showEmoji = false" id="inputText" @blur="campaignNameBlur" placeholder="每次发送消耗1七里币" class="common-input" v-model="content" clearable >
                    <template #button>
                        <span v-if="false" style="color: rgba(207,169,114,0.44);font-size: 10px;">{{content.length}}/50</span>
                        <div @click="showEmoji = !showEmoji" style="font-size: 20px;">
                            <span style="">😀</span>
                        </div>
                    </template>
                </van-field>

                <van-button type="info"  native-type="submit" :loading="showLoading"
                            style="height: 33px;font-weight: bold;width: 100px;font-size: 12px;margin-left: 5px;"
                            loading-text=""  @click="send"
                            color="#0f78e8">
                    发送
                </van-button>

            </div>
            <div v-if="showEmoji" style="margin: 0 auto;width: 100%; font-size: 20px;overflow-y: auto;box-sizing: border-box;text-align: left">
                <span @click="selectEmoji(e)" v-for="(e, i) in emojiList">{{e}}</span>
            </div>
        </div>

    </div>
</template>

<script>
    import {request} from '@/api/request'
    import Toast from "vant/lib/toast";
    import myemoji from '@/components/myemoji';

    export default {
        name: "shj-chat",
        components: {
            myemoji
        },
        data() {
            return {
                showLoading: false,
                emojiList: [
                    '😀', '😁', '😂','😃','😄','😅','😆','😉','😊','😋','😎','😍','😘','😗','😙','😚','😇','😐','😑','😶','😏','😣','😥','😮','😯','😪','😫','😴','😌',
                    '😛','😜','😝','😒','😓','😔','😕','😲','😷','😖','😞','😟','😤','😢','😭','😦','😧','😨','😬','😰','😱','😳','😵','😡','😠','😈','👿','👹','👺',
                    '💀','👻','👽','👦','👧','👨','👩','👴','👵','👶','👱','👮','👲','👳','👷','👸','💂','🎅','👰','👼','💆','💇','🙍','🙎','🙅','🙆','💁','🙋','🙇',
                    '🙌','🙏','👤','👥','🚶','🏃','👯','💃','👫','👬','👭','💏','💑','👪','💪','👈','👉','☝','👆','👇','✌','✋','👌','👍','👎','✊','👊','👋','👏','👐',
                    '✍','👣','👀','👂','👃','👅','👄','💋','👓','👔','👕','👖','👗','👘','👙','👚','👛','👜','👝','🎒','💼','👞','👟','👠','👡','👢','👑','👒','🎩',
                    '🎓','💄','💅','💍','🌂'
                ],
                content: '',
                campaignNameIndex: 0,
                qlb: '0.00',
                messageList: [],
                userId: '',
                showEmoji: false,
                interval: null,
                clientHeight: 0,
                headerImg: '',
            }
        },
        mounted() {
            /*this.clientHeight = `${document.documentElement.clientHeight}`;
            let that = this;
            window.onresize = function(){
                console.log('window height change 111', that.interval)
                clearInterval(that.interval);
            }*/
            this.headerImg = localStorage.getItem('headerImg');
            this.userId = localStorage.getItem('userId');
            this.queryMessage();
            this.getQlb();
            this.addInputBlur();
            //this.interval = setInterval(this.queryMessage, 5000);
            //console.log('this.interval ' + this.interval)
        },
        beforeDestroy () {
            //clearInterval(this.interval);
        },
        methods: {
            send() {
                if (!this.content) {
                    Toast('请输入喇叭内容');
                    return;
                }
                this.showLoading = true;
                let options = {
                    url:"/zfapp/shoutForTSJX",
                    data: {
                        msg: this.content
                    }
                };

                request(options).then(res => {
                    this.showLoading = false;
                    if (res.data.success) {
                        Toast('全服喇叭发送成功');
                        this.getQlb();
                        this.queryMessage();
                        this.showEmoji = false;
                        this.content = '';
                    }
                    else {
                        Toast(res.data.error.message);
                    }
                });
            },
            queryMessage() {
                let options = {
                    url:"/zfapp/shoutLogListFotTSJX",
                    data: {}
                };
                request(options).then(res => {
                    if (res.data.success) {
                        this.messageList = res.data.data;
                        setTimeout(function() {
                            const scrollHeight = document.getElementById("msgListView").scrollHeight;
                            document.getElementById("msgListView").scroll(0, scrollHeight);
                        }, 100)
                    }
                    else {
                        Toast(res.data.error.message);
                    }
                });
            },
            handleEmoji(e) {
                if (this.content.length + e.length > 50) {
                    return ;
                }
                if (this.content) {
                    let index = this.campaignNameIndex;
                    let name = this.content;
                    this.content = name.slice(0,index) + e + name.slice(index);
                }
                else {
                    this.content = e;
                }
                console.log(e.length);
                this.campaignNameIndex = this.campaignNameIndex + e.length;
                this.showEmoji = false;
            },
            getQlb() {
                let options = {
                    url:"/zfapp/getUserBalanceList",
                    data: {}
                };
                request(options).then(res => {
                    if (res.data.success) {
                        this.qlb = res.data.data.qlBalance;
                    }
                    else {
                        Toast(res.data.error.message);
                    }
                });
            },
            back() {
                this.$parent.$parent.closeChatPage();
            },
            campaignNameBlur(e){//记录光标位置
                this.campaignNameIndex = e.srcElement.selectionStart;
            },
            addInputBlur() {
                let isIOS = !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) // 检测是否为ios
                if(!isIOS) {
                    console.log('不是iOS，取消blur事件');
                    return ;
                }
                let t = document.getElementById('inputText');
                t.removeEventListener('blur', function(){ window.scrollTo(0,0)});
                t.addEventListener('blur', function(){window.scrollTo(0,0);console.log('触发了')}, false)
            },
            selectEmoji(e){
                if (this.content.length + e.length > 50) {
                    return ;
                }
                if (this.content) {
                    let index = this.campaignNameIndex;
                    let name = this.content;
                    this.content = name.slice(0,index) + e + name.slice(index);
                }
                else {
                    this.content = e;
                }
                this.campaignNameIndex = this.campaignNameIndex + e.length;
            }
        }
    }
</script>

<style scoped>

    .body{
        overflow-y: auto;
        height: 210px;
        padding: 0 10px;
    }
    .common-input {
        background: #ffffff;
        font-size: 14px;
        color: #333333;
        height: 33px;
    }

    .van-cell {
        padding: 5px 10px;
    }
    /deep/ .van-field__control {
        color: #333333;
    }
    .van-cell::after {
        border-bottom: none;
    }
</style>