<template>
    <div class="common-body-view">
        <div  class="common-title">
            <img @click="back" src='../../img/left.png' style="width: 20px; height: 20px;">
            <span class="common-title-text">修改密码</span>
        </div>

        <div class="main">
            <div class="green-tips-views">
                <span>为了帐号安全请定期修改密码</span>
            </div>

            <div class="input-view">
                <van-field label="原密码" label-width="70"  maxlength="12" id="password1" type="password" placeholder="请输入原密码" class="common-input" clearable v-model="password_1" />
            </div>

            <div class="input-view">
                <van-field label="新密码" label-width="70"  maxlength="12" id="password2" type="password" placeholder="请输入新密码" class="common-input" clearable v-model="password_2" />
            </div>

            <div class="input-view">
                <van-field label="确认密码" label-width="70"  maxlength="12" id="password3" type="password" placeholder="请确认新密码" class="common-input" clearable v-model="password_3" />
            </div>

            <van-button type="info" block native-type="submit" :loading="showLoading"
                        style="height: 35px;font-weight: bold;width: 98%;margin: 16px auto 0;"
                        loading-text="正在提交"  @click="submit"
                        color="#0f78e8">
                立即修改
            </van-button>
        </div>

    </div>
</template>

<script>
    import {request} from '@/api/request'
    import Toast from "vant/lib/toast";
    import crypto from '@/utils/crypto.js';

    export default {
        name: "sdk-password",
        components: {
        },
        data() {
            return {
                showLoading: false,
                from: '',
                password_1: '',
                password_2: '',
                password_3: '',
                passwordRegexp: /^[a-zA-Z0-9~!@#$%^&*()_+.-=]{6,12}$/,
            }
        },
        mounted () {
            this.from = localStorage.getItem('from');
            this.addInputBlur();
        },
        methods: {
            back() {
                this.$parent.$parent.closePasswordPage();
            },
            submit() {
                let ok = this.validatorInput();
                if (ok !== 'ok') {
                    Toast(ok);
                    return ;
                }

                this.showLoading = true;
                let options = {
                    url:"/zfapp/modifyPasswordByOld",
                    data: {
                        newUserPwd: crypto.encrypt(this.password_2),
                        userPwd: crypto.encrypt(this.password_1)
                    }
                };

                request(options).then(res => {
                    this.showLoading = false;
                    if (res.data.success) {
                        Toast('密码修改成功,请重新登录');
                        setTimeout(() => {
                            this.exit();
                        }, 1500)
                    }
                    else {
                        Toast(res.data.error.message);
                    }
                });
            },
            exit() {
                this.$parent.$parent.logout();
            },
            validatorInput() {
                if (!this.password_1) {
                    return "请输入原密码";
                }
                if (!this.password_2) {
                    return "请输入新密码";
                }
                if (!this.password_3) {
                    return "请再次输入新密码";
                }
                if(!this.passwordRegexp.test(this.password_1)) {
                    return "原密码为6-12位数字字母特殊符号";
                }
                if(!this.passwordRegexp.test(this.password_2)) {
                    return "新密码为6-12位数字字母特殊符号";
                }
                if(this.password_2 !== this.password_3) {
                    return "两次密码输入不一致，请重新输入";
                }
                if(this.password_1 === this.password_2) {
                    //return "新密码不能和旧密码相同";
                }
                return "ok";
            },
            addInputBlur() {
                let isIOS = !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) // 检测是否为ios
                if(!isIOS) {
                    console.log('不是iOS，取消blur事件');
                    return ;
                }
                let password1 = document.getElementById('password1');
                password1.removeEventListener('blur', function(){ window.scrollTo(0,0)});
                password1.addEventListener('blur', function(){window.scrollTo(0,0)}, false);

                let password2 = document.getElementById('password2');
                password2.removeEventListener('blur', function(){ window.scrollTo(0,0)});
                password2.addEventListener('blur', function(){window.scrollTo(0,0)}, false);

                let password3 = document.getElementById('password3');
                password3.removeEventListener('blur', function(){ window.scrollTo(0,0)});
                password3.addEventListener('blur', function(){window.scrollTo(0,0)}, false);

            },
        }
    }
</script>

<style scoped>
    .input-view{
        margin-top: 10px;
    }
    .van-cell {
        padding: 7px 10px;
    }
    /deep/ .van-field__control {
        color: #333333;
    }
</style>