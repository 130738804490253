<template>
    <div class="login-main">
        <div v-if="showBack"  @click="back" style="display: flex;align-items: center;justify-content: center; width: 25px;height: 25px; text-align: center;
                    position: absolute;top: 40px;margin-left: 20px;background-color: rgba(0,0,0,.2);border-radius: 100%;">
            <van-icon name="arrow-left" size="20"/>
        </div>
        <!-- Step 1 登录 -->
        <div v-if="step === 1">
            <div style="display: flex;align-items: center;width: 100%;background-color: #6e8ec9;height: 80px;">
                <img src="../../static/main-round.png" class="logo" style="width: 50px;height: 50px;">
                <span style="font-size: 22px;font-weight: bold;color: #ffffff;margin-left: 20px;">七里网络</span>
            </div>
            <div class="form-view" >
                <div class="logo-view">
                    <span class="top-span">欢迎登录</span>
                </div>
                <div class="ts-form">
                    <van-field placeholder="请输入七里帐号" @focus="inputOnFocus(1)" @blur="inputBlur(1)" :style="usernameInputStyle" v-model="username" clearable>
                        <template #button>
                            <van-icon name="arrow-down" @click="selectAccount"/>
                        </template>
                    </van-field>

                    <van-field class="margin-10"  @focus="inputOnFocus(2)" @blur="inputBlur(2)" :style="passwordInputStyle" type="password"  placeholder="请输入登录密码" v-model="password" clearable></van-field>

                    <div class="margin-10"  style="display: flex;justify-content: space-between">
                        <div>
                            <van-checkbox v-model="remPassword" icon-size="15px" checked-color="#606CFD">
                                <span style="color: #999999;font-size: 13px;">记住密码</span>
                            </van-checkbox>
                        </div>
                        <span style="color: #5A6EFC; font-size: 13px;" @click="goForgetPage()">忘记密码？</span>
                    </div>

                    <van-button color="#5A6EFC" class="main-button margin-10" block @click="doLogin()">登录</van-button>

                    <div class="margin-10" style="display: flex;align-items: center" :style="!gameSpeedSwitch ? 'justify-content: flex-end;' : 'justify-content: space-between'">
                        <van-checkbox v-model="openSpeed" icon-size="15px" checked-color="#ef2929" v-if="gameSpeedSwitch">
                            <span style="color: #ef2929;font-size: 13px;">进入加速版</span>
                        </van-checkbox>

                        <div style="color: #999999; font-size: 13px;">
                            没有帐号?<span style="color: #5A6EFC;margin-left: 6px" @click="goRegisterPage">立即注册</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <van-popup v-model="showAccountSelector" position="bottom" >
            <van-picker
                    title="请选择需要登录的账号"
                    show-toolbar
                    :columns="accountNameList"
                    @confirm="onConfirmAccount"
                    @cancel="showAccountSelector = false"
            />
        </van-popup>

        <!-- Step 2 实名认证 -->
        <div class="form-view" v-if="step ===2" style="padding: 0">
            <div style="background-color: #FFFAEF;border-radius: 5px 5px 0 0;text-align: left;padding: 10px 20px;">
                <div style=" font-size: 20px;font-weight: 600;">
                    <span>实名认证</span>
                </div>
                <div style="font-size: 12px;color:#666666;margin-top: 10px">
                    <span>应国家防沉迷要求，进入游戏需进行实名认证，平台承诺，此信息只会用于防沉迷校验。</span>
                </div>
            </div>
            <div style="padding: 10px 27px 20px;">
                <van-field placeholder="请输入真实姓名"  v-model="realName" clearable></van-field>

                <van-field class="margin-10"   placeholder="请输入身份证号码" v-model="carId" clearable></van-field>
                <van-button color="#5A6EFC" class="main-button margin-30" block @click="doRealName">立即认证</van-button>
            </div>
        </div>

        <!-- Step 3 绑定手机 -->
        <div class="form-view" v-if="step === 3" style="padding: 0">
            <div style="background-color: #F0F3FE;border-radius: 5px 5px 0 0;text-align: left;padding: 10px 20px;">
                <div style=" font-size: 20px;font-weight: 600;">
                    <span>绑定手机</span>
                </div>
                <div style="font-size: 12px;color:#5777D9;margin-top: 10px">
                    <span>建议绑定手机号，平台可通过手机号找回密码，平台将以绑定手机号作为账号拥有者的标记。</span>
                </div>
            </div>
            <div style="padding: 10px 27px 20px;">
                <van-field placeholder="请输入手机号码"  v-model="phoneNumber" type="number" clearable></van-field>

                <van-field class="margin-10"  maxlength="6" placeholder="请输入验证码" type="number" v-model="verificationCode" clearable>
                    <template #button>
                        <van-count-down
                                v-show="showCountDown"
                                ref="countDown"
                                :time="60000"
                                :auto-start="false"
                                format="重新发送(ss秒)"
                                style="font-size: 13px;color: #888888"
                                @finish="finish"
                        />
                        <span style="color: #5A6EFC;font-size: 13px;" v-show="!showCountDown" @click="sendCode">发送验证码</span>
                    </template>
                </van-field>
                <van-button color="#5A6EFC" class="main-button margin-30" block @click="doBindTel">绑定</van-button>
                <div class="margin-10" style="display: flex;justify-content: flex-end">
                    <div style="color: #999999; font-size: 13px;">
                        不想绑定手机?<span style="color: #5A6EFC;margin-left: 6px" @click="goGame">直接进入游戏</span>
                    </div>
                </div>
            </div>
        </div>

        <myloading v-show="showLoading"></myloading>

        <van-popup
                v-model="showVerify"
                :close-on-click-overlay="false">
            <div class="line-two">
                <slide-verify
                        :l="slideObj.l"
                        :r="slideObj.r"
                        :w="slideObj.w"
                        :h="slideObj.h"
                        slider-text="请拖动滑块完成验证"
                        ref="slideVerify"
                        @success="onSuccess"
                        @fail="onFail"
                        @refresh="onRefresh"
                        :imgs="imgs"
                        :accuracy='3'
                        :show="false"
                ></slide-verify>
                <!-- accuracy容错率 -->
            </div>
        </van-popup>

    </div>
</template>

<script>
    import {request,get} from '@/api/request'
    import crypto from '@/utils/crypto.js';
    import {Toast, Dialog} from 'vant'
    import myloading from '@/components/myloading'

    export default {
        name: "login",
        components: {
            myloading
        },
        watch: {
            openSpeed(v) {
                if (v) {
                    Dialog.alert({
                        title: '温馨提示',
                        messageAlign: 'left',
                        message: '1.  加速模式无法充值，首次进游戏请勿勾选\n2. 退出加速模式请点击左侧小圆圈，退出后再重新登陆。'
                    })
                }
            },
        },
        data() {
            return {
                showLoading: false,
                showVerify: false,
                focusInput: 'outline: 2px #3478F6 solid;',
                usernameInputStyle: '',
                passwordInputStyle: '',
                isReadName: false,
                remPassword: true,
                openSpeed: false,
                username: '',
                password: '',
                phoneNumber: '',
                showCountDown: false,
                verificationCode: '',
                realName:'',
                carId:'',
                isApp: '',
                gameId: '',
                step: 1,
                showAccountSelector: false,
                accountList: [],
                accountNameList: [],
                userInfo: {},
                from: '',

                gameSpeedSwitch: false,
                gameSpeedEnable: false,

                channelRegexp: /^\d{1,5}$/,
                usernameRegexp: /^[a-zA-Z0-9_]{6,12}$/,
                passwordRegexp: /^[a-zA-Z0-9~!@#$%^&*()_+.-=]{6,12}$/,
                phoneNumberRegexp: /^1\d{10}$/,
                verificationCodeRegexp: /^\d{6}$/,
                realNameRegexp: /^[\u4E00-\u9FA5·]{1,20}$/,
                carIdRegexp: /^[1-9][0-9]{5}([1][9][0-9]{2}|[2][0][0|1][0-9])([0][1-9]|[1][012])([0][1-9]|[1|2][0-9]|[3][0|1])[0-9]{3}([0-9]|[X])$/,
                showBack: false,
                slideObj: {
                    l: 42, //滑动碎片的大小
                    r: 10, //滑动碎片的圆角
                    w: 310, //画布的宽
                    h: 155, //画布的高
                },
                imgs: [
                    require('@/static/verify/1.png'),
                    require('@/static/verify/2.png'),
                    require('@/static/verify/3.png'),
                ],
            }
        },
        mounted() {
            this.accountList = JSON.parse(localStorage.getItem('accountList'));
            if (!!this.accountList && this.accountList.length > 0) {
                /*this.accountList.forEach((v,i) => {
                    this.accountNameList.push(v.username);
                });*/

                for (let i = 0; i < this.accountList.length; i++) {
                    let item = this.accountList[i];
                    this.accountNameList.push(item.username);
                }

                let lastAccount = this.accountList[0];
                this.username = lastAccount.username;
                this.password = crypto.decrypt(lastAccount.password);
            }
            this.initSystemInfo();
            this.handPage();
            this.initVerifyPopup();
        },
        methods: {
            back() {
                let historyLength =window.history.length;
                window.history.go(-(historyLength -1));
            },
            initVerifyPopup() {
                let clientWidth = document.documentElement.clientWidth; //获取HTML文档所在窗口的当前宽度
                let ratio = (clientWidth / 375) * 20; //计算基数为40的比例,然后动态计算画布在当前窗口的宽高，撑开弹窗
                this.slideObj.l = 1.7 * ratio;
                this.slideObj.r = 0.3 * ratio;
                this.slideObj.w = 12.6 * ratio;
                this.slideObj.h = 7.8 * ratio;
            },
            onSuccess() {
                setTimeout(() => {
                    this.showVerify = false;
                    this.doLogin('verify');
                    console.log("成功回调");
                }, 800)
                setTimeout(() => {
                    this.$refs.slideVerify.refresh();
                }, 1200)
            },
            onFail() {
                console.log("失败回调");
            },
            onRefresh() {
                console.log("刷新回调");
            },
            async initSystemInfo() {
                //如果是退出或者是ios跳转 则不处理链接参数
                if (localStorage.getItem('login_status')) {
                    this.gameId = localStorage.getItem('gameId');
                    this.isApp = localStorage.getItem('isApp');
                    this.from = localStorage.getItem('from');

                    if (localStorage.getItem('gameSpeedSwitch')) {
                        this.gameSpeedSwitch = localStorage.getItem('gameSpeedSwitch') === 'true';
                    }
                    else {
                        this.gameSpeedSwitch = true;
                    }

                    return;
                }

                let channel = this.$route.query.channelId || '1';
                if (!this.channelRegexp.test(channel)) {
                    channel = await this.getConfigChannel(channel);
                }

                this.gameId = this.$route.query.gameId || '206';
                localStorage.setItem('gameId', this.gameId);

                let systemInfo = {
                    device : this.$route.query.device || 'H5',
                    systemVersion : this.$route.query.systemVersion || 'H5',
                    udid : this.$route.query.udid || 'WEB',
                    channelId : channel,
                    gameId : this.gameId,
                    sdkVersion: this.$route.query.sdkVersion || '200'
                }

                localStorage.setItem('systemInfo', JSON.stringify(systemInfo));

                this.isApp = this.$route.query.isApp || '';
                localStorage.setItem('isApp', this.isApp);

                let wxPay = this.$route.query.wxPay || '';
                localStorage.setItem('wxPay', wxPay);

                this.from = this.$route.query.from || 'H5'
                localStorage.setItem('from', this.from);

                this.gameSpeedSwitch =  this.isApp !== '2' && this.from !== 'tsjxf_android' && this.from !== 'tsjxf_windows';
                localStorage.setItem('gameSpeedSwitch', this.gameSpeedSwitch);
            },
            handPage() {
                let metaTag = document.getElementById('metaTag');
                if (this.isApp === '1' || this.from === 'qiliApp') {
                    metaTag.content = 'width=device-width,initial-scale=1.0,maximum-scale=1.0,user-scalable=no,viewport-fit=cover';
                }

                uni.postMessage({
                    data: {
                        action: 'uniKeyboardFlag',
                        msg: false
                    }
                });

                if (this.from === 'qiliApp') {
                    this.showBack = true;
                    let os = localStorage.getItem('qiliapp_os');
                    this.openSpeed = os === '1';
                    let login = localStorage.getItem('qiliapp_login');
                    if (login === '1') {
                        this.username = localStorage.getItem('qiliapp_username');
                        this.password = crypto.decrypt(localStorage.getItem('qiliapp_password'));
                        this.doLogin();
                    }
                }
            },
            getConfigChannel(c) {
                return new Promise((resolve, reject) => {
                    let url= "/app/transformChannel?channelConfig=" + c;
                    get(url).then(res => {
                        if (res.data.success) {
                            console.log('获取配置渠道信息，配置值：' + c + " ,获取结果：" + res.data.data);
                            resolve(res.data.data);
                        }
                        else {
                            resolve("1");
                        }
                    }).catch(e => {
                        resolve("1");
                    });
                })
            },
            selectAccount() {
                if (this.accountNameList.length === 0) {
                    Toast('当前没有帐号可以选择');
                    return ;
                }
                this.showAccountSelector = true;
            },
            onConfirmAccount(value, index) {
                if (this.accountNameList.length === 0) {
                    Toast('当前没有帐号可以选择');
                    return ;
                }
                let account = this.accountList[index];
                this.username = account.username;
                this.password = crypto.decrypt(account.password);
                this.showAccountSelector = false;
            },
            change(){
                this.remPassword = !this.remPassword;
            },
            inputOnFocus(e) {
                if (e === 1) {
                    this.usernameInputStyle = this.focusInput;
                }
                else if (e === 2) {
                    this.passwordInputStyle = this.focusInput;
                }
            },
            inputBlur(e) {
                if (e === 1) {
                    this.usernameInputStyle = '';
                }
                else if (e === 2) {
                    this.passwordInputStyle = '';
                }
            },
            sendCode() {
                let ok = this.checkTel();
                if (ok !== 'ok') {
                    Toast(ok);
                    return ;
                }
                this.showLoading = true;
                let options = {
                    url:"/zfapp/sendSms",
                    data: {
                        phone: this.phoneNumber,
                        type: 2
                    }
                };
                request(options).then(res => {
                    this.showLoading = false;
                    if (res.data.success) {
                        this.showCountDown = true;
                        this.$refs.countDown.start();
                        Toast('验证码发送成功');
                    }
                    else {
                        Toast(res.data.error.message);
                    }
                });
            },
            finish() {
                this.showCountDown = false;
                this.$refs.countDown.reset();
            },
            goRegisterPage() {
                this.$router.push("/register");
            },
            goForgetPage() {
                this.$router.push("/forget");
            },
            isRealName() {
                if(this.userInfo.isUserCheck === '1') {
                    this.isBindPhone();
                }
                else {
                    this.step = 2;
                }
            },
            isBindPhone() {
                if (!!this.userInfo.phone) {
                    this.goGame();
                }
                else {
                    this.step = 3;
                }
            },
            goGame() {
                /**
                 * 是否主菜单展示加速按钮，两种情况展示：
                 * 1. 登录页面没有gameSpeedSwitch开关  说明是安卓
                 * 2. 登录页面有gameSpeedSwitch开关，且开关打开
                 */

                if (!this.gameSpeedSwitch) {
                    this.gameSpeedEnable = true;
                }
                else if(this.openSpeed) {
                    this.gameSpeedEnable = true;
                }
                localStorage.setItem('gameSpeedEnable', this.gameSpeedEnable);

                if (this.gameId === '397') {
                    this.$router.replace("/ts/jxf");
                }
                else {
                    this.$router.replace("/game");
                }

            },
            doLogin(type="login") {
                let ok = this.checkLogin();
                if (ok !== 'ok') {
                    Toast(ok);
                    return ;
                }
                this.showLoading = true;
                let selectSpeed = this.openSpeed ? "1" : "";
                localStorage.setItem('selectSpeed', selectSpeed);
                let options = {
                    url:"/zfapp/login",
                    data: {
                        userName: this.username,
                        userPwd: crypto.encrypt(this.password),
                        loginType: type,
                        selectSpeed: selectSpeed,
                    }
                };
                request(options).then(res => {
                    this.showLoading = false;
                    console.log(res);
                    if (res.data.success) {
                        let userInfo = res.data.data;
                        this.userInfo = userInfo;
                        localStorage.setItem('userInfo', JSON.stringify(userInfo));
                        localStorage.setItem('token', userInfo.token);
                        localStorage.setItem('userId', userInfo.userId);
                        localStorage.setItem('tel', userInfo.phone);
                        if (userInfo.codePageUrl) {
                            localStorage.setItem('codePageUrl', userInfo.codePageUrl);
                        }
                        uni.postMessage({
                            data: {
                                action: 'uniToken',
                                msg: userInfo.token
                            }
                        });
                        this.saveAccountLocal();
                        this.isRealName();
                    }
                    else if (res.data.error.code === '300') {
                        this.showVerify = true;
                    }
                    else {
                        Toast(res.data.error.message);
                    }
                });
            },
            saveAccountLocal() {
                if (this.remPassword) {
                    let account = {
                        username: this.username,
                        password: crypto.encrypt(this.password)
                    };
                    let newArray = [];
                    let list = JSON.parse(localStorage.getItem('accountList'));
                    if (!!list) {
                        newArray.push(account);
                        for (let i = 0; i < list.length; i++) {
                            if (i >= 10) {
                                break;
                            }
                            if (list[i].username !== this.username) {
                                newArray.push(list[i]);
                            }
                        }
                    }
                    else {
                        newArray.push(account);
                    }
                    localStorage.setItem('accountList', JSON.stringify(newArray));
                }
            },
            doRealName() {
                let ok = this.realnameCheck();
                if (ok !== 'ok') {
                    Toast(ok);
                    return ;
                }
                this.showLoading = true;
                let options = {
                    url:"/zfapp/personCheck",
                    data: {
                        realName: this.realName,
                        cardNo: this.carId
                    }
                };
                request(options).then(res => {
                    this.showLoading = false;
                    if (res.data.success) {
                        this.userInfo.isUserCheck = res.data.data.isUserCheck;
                        localStorage.setItem('userInfo', JSON.stringify(this.userInfo));
                        this.isBindPhone();
                    }
                    else {
                        Toast(res.data.error.message);
                    }
                });
            },
            doBindTel() {
                let ok = this.checkBindTel();
                if (ok !== 'ok') {
                    Toast(ok);
                    return ;
                }
                this.showLoading = true;
                let options = {
                    url:"/zfapp/bindPhone",
                    data: {
                        phone: this.phoneNumber,
                        checkCode: this.verificationCode
                    }
                };
                request(options).then(res => {
                    this.showLoading = false;
                    if (res.data.success) {
                        this.userInfo.phone = res.data.data;
                        localStorage.setItem('userInfo', JSON.stringify(this.userInfo));
                        this.goGame();
                    }
                    else {
                        Toast(res.data.error.message);
                    }
                });
            },
            checkLogin() {
                let errorinfo = 'ok';
                if (!this.username) {
                    errorinfo = "请输入七里帐号"
                } else if (!this.password) {
                    errorinfo = "请输入密码";
                } else if (!this.usernameRegexp.test(this.username)) {
                    errorinfo = "七里帐号为6-12位数字或字母";
                } else if (!this.passwordRegexp.test(this.password)) {
                    errorinfo = "密码为6-12位数字字母特殊符号";
                }
                return errorinfo;
            },
            checkBindTel() {
                let errorinfo = 'ok';
                if (!this.phoneNumber) {
                    errorinfo = "请输入手机号码"
                } else if (!this.verificationCode) {
                    errorinfo = "请输入验证码";
                } else if (!this.phoneNumberRegexp.test(this.phoneNumber)) {
                    errorinfo = "手机号码格式错误";
                } else if (!this.verificationCodeRegexp.test(this.verificationCode)) {
                    errorinfo = "请输入6位数字验证码";
                }
                return errorinfo;
            },
            checkTel() {
                let errorinfo = 'ok';
                if (!this.phoneNumber) {
                    errorinfo = "请输入手机号码"
                } else if (!this.phoneNumberRegexp.test(this.phoneNumber)) {
                    errorinfo = "手机号码格式错误";
                }
                return errorinfo;
            },
            realnameCheck() {
                let errorinfo = 'ok';
                if (!this.realName) {
                    errorinfo = "请输入姓名"
                } else if (!this.carId) {
                    errorinfo = "请输入身份证号码";
                } else if (!this.realNameRegexp.test(this.realName)) {
                    errorinfo = "姓名格式不正确";
                } else if (!this.carIdRegexp.test(this.carId)) {
                    errorinfo = "身份证号码格式不正确";
                }
                return errorinfo;
            },
        }
    }

</script>

<style scoped>
    .login-main {
        width: 100%;
        height: 100vh;
        overflow: hidden;
        background-color: #f1f4f6;
    }

    .form-view{
        box-sizing: border-box;
        width: 90%;
        max-width: 400px;
        min-width: 300px;
        margin: 120px auto 0;
        background-color: rgba(255,255,255,0.9);
        border-radius: 5px;
        min-height: 20%;
        padding: 20px 27px 0;
    }
    .top-span{
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 10px;
    }
    .ts-form{
        padding-top: 10px;
        padding-bottom: 20px;
    }
    .logo {
        margin-left: 30px;
    }
    .logo-view{
        display: flex;
        align-items: flex-end;
    }
    .main-button{
        font-size: 16px;
        height: 40px;
    }

    .van-field{
        outline: 1px #dbdbdb solid;
        border-radius: 3px;
    }
    .van-field__control:focus{
        outline: 1px red solid !important;
        border-radius: 3px;
    }
    .line-two {
        padding: 1rem;
        background: white;
    }
</style>