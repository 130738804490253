<template>
    <div class="common-body-view">
        <div  class="common-title">
            <img @click="back" src='../../img/left.png' style="width: 20px; height: 20px;">
            <span class="common-title-text">{{ contentType === "1" ? "游戏攻略" : "限时活动" }}</span>
        </div>
        <div class="content-list">
            <div class="content-list-item" @click="toViewPage(item.contentId)" v-if="contentData.length !== 0" v-for="item in contentData">
                <div v-show="!haveRead.includes(item.contentId)">
                    <div style="width: 10px;height: 10px;border-radius: 99px;background-color: #e5260c;"></div>
                </div>
                <div style="margin-left: 10px;flex: 1;" class="text-hidden">{{ item.contentName }}</div>
                <div>
                    <svg t="1721626648849" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="10670" width="20" height="20">
                        <path d="M704.752941 491.700706L268.408471 55.416471a32.466824 32.466824 0 0 1 45.899294-45.899295l458.992941 458.992942a32.466824 32.466824 0 0 1-4.216471 49.392941l-441.645176 473.690353a32.527059 32.527059 0 0 1-47.465412-44.272941L704.752941 491.700706z" fill="#cdcdcd" p-id="10671"></path>
                    </svg>
                </div>
            </div>
            <div v-if="contentData.length === 0" style="width: 100%;height: 200px;font-size: 20px;font-weight: 700;">
                暂无
            </div>
        </div>

    </div>
</template>

<script>
import {request} from "@/api/request";

export default {
    name: "ContentList",
    props: {
        contentType: {
            type: String,
            default: '1'
        }
    },
    data(){
        return {
            contentData: [],
            haveRead: [],
        }
    },
    watch: {
        "contentType": {
            immediate: true,
            handler(){
                this.loadContentList();
            }
        }
    },
    mounted() {
        this.checkHaveRead();
    },
    methods: {
        back() {
            this.$parent.$parent.closeContentListPage();
        },
        checkHaveRead(){
            let haveRead = localStorage.getItem("haveRead");
            if(haveRead) {
                this.haveRead = JSON.parse(haveRead);
            }
        },
        toViewPage(contentId){
            this.haveRead.push(contentId);
            localStorage.setItem("haveRead", JSON.stringify(this.haveRead));
            this.$parent.$parent.openContentViewPage(contentId);
        },
        loadContentList() {
            let options = {
                url: `/content/getContentList`,
                data: {
                    contentType: this.contentType,
                }
            }
            request(options).then(res => {
                if(res.data.success) {
                    this.contentData = res.data.data
                }
            })
        }
    }
}
</script>

<style scoped>
.common-body-view {
    border-radius: 8px;
    user-select: none;
}
.content-list {
    /*height: calc(100% - 60px);*/
    height: 250px;
    overflow: hidden;
    overflow-y: auto;
}
.content-list-item {
    width: 90%;
    height:60px;
    background-color: #fff;
    margin: 10px auto 0;
    padding: 0 10px;
    border-radius: 8px;
    text-align: left;
    
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    position: relative;
}
/*.content-list-item div:nth-child(2) {*/
/*    width: 80%;*/
/*}*/

.text-hidden {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

/*定义滚动条高宽及背景
 高宽分别对应横竖滚动条的尺寸*/
::-webkit-scrollbar
{
    width:5px;
    height:10px;
    background-color:#F5F5F5;
}
/*定义滚动条轨道
 内阴影+圆角*/
::-webkit-scrollbar-track
{
    /*-webkit-box-shadow:inset 0 0 6px rgba(0,0,0,0.3);*/
    border-radius:10px;
    background-color:#F5F5F5;
}
/*定义滑块
 内阴影+圆角*/
::-webkit-scrollbar-thumb
{
    border-radius:10px;
    /*-webkit-box-shadow:inset 0 0 6px rgba(0,0,0,.3);*/
    background-color: #b0b0b0;
}
</style>