<template>
    <div class="main">
        <div class="center">
        <img style="width: 100%;margin-top: -5px;" src="../static/reddetail/top.png">
        <div @click="back"
             style="position: absolute;top: 50px;left: 15px; text-align: left;display: flex;align-items: center;justify-content: center; width: 30px;height: 30px;">
            <img src="../static/reddetail/left.png" style="width: 12px;height: 23px;">
        </div>
        <div style="display: flex;align-items: center;justify-content: center;margin-top: 30px;">
            <img src="../static/main-round.png" style="width: 25px;">
            <span class="title-text">七里网络游戏的红包</span>
            <img src="../static/reddetail/ping.png" style="width: 20px;margin-left: 5px;">
        </div>
        <div style="font-size: 14px;margin-top: 20px;color: #FCCD6A;">
            <span>恭喜发财，大吉大利</span>
        </div>
        <div style="font-size: 14px;margin-top: 40px;color: #FCCD6A;">

            <div v-if="!!redDetail.myMoney">
                <span style="font-size: 40px;font-weight: bold">{{redDetail.myMoney}}</span>
                <span>元</span>
            </div>
            <span v-if="!redDetail.myMoney">{{redDetail.myMsg}}</span>


        </div>
        <div style="font-size: 13px;color: #DDDDDD;margin: 30px 20px 10px;text-align: left;">
            <span>{{redDetail.detailMsg}}</span>
        </div>

        <div v-for="(item, i) in redDetail.userInfoList">
            <div class="get-view" :class="i === 0 ? 'get-view-first' : ''">
                <div style="display: flex;">
                    <img :src="item.imgUrl" style="width: 45px;height: 45px;">
                    <div style="display: flex;flex-direction: column;align-items: flex-start;justify-content: space-around;margin-left: 15px;font-size: 13px;">
                        <span style="color:#702300;font-weight: 600;">{{item.userName}}</span>
                        <span style="color:#FFFFFF;">{{item.time}}</span>
                    </div>
                </div>

                <div style="display: flex;flex-direction: column;align-items: flex-end;">
                    <span style="color:#702300;font-weight: 600;">{{item.money}}</span>
                    <img v-if="i === 0" src="../static/reddetail/most.png" style="width: 76px;">
                </div>
            </div>
        </div>
        <myloading v-show="showLoading"></myloading>
        </div>
    </div>
</template>

<script>
    import {request} from '@/api/request'
    import {Toast} from 'vant'
    import myloading from '@/components/myloading'

    export default {
        name: "redpack",
        components: {
            myloading
        },
        data() {
            return {
                showLoading: false,
                redDetail: {},
            }
        },
        mounted() {
        },
        methods: {
            back() {
                this.$parent.$parent.closeRedpackPage();
            },
            queryData(redId) {
                this.showLoading = true;
                let options = {
                    url:"/zfapp/redDetail",
                    data: {
                        redId: redId
                    }
                };
                request(options).then(res => {
                    this.showLoading = false;
                    if (res.data.success) {
                        this.redDetail = res.data.data;
                    }
                    else {
                        Toast(res.data.error.message);
                    }
                });
            },
        }
    }
</script>

<style scoped>
    .main {
        width: 100vw;
        height: 100%;
        background-image: url(../static/reddetail/back.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
        flex: 1;
        overflow: scroll;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        padding-bottom: 13px;
    }
    .center{
        width: 100%;
        height: 100%;
        overflow: scroll;
    }

    .title-text {
        font-weight: 600;
        font-size: 19px;
        margin-left: 10px;
        background: linear-gradient(0deg, #FCCD6A 0%, #FFFFFF 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .get-view{
        height: 70px;
        background-image: url(../static/reddetail/red_panel.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 0 15px;
        box-sizing: border-box;
        margin: 0 20px;
    }
    .get-view-first{
        background-image: url(../static/reddetail/red_first.png);
    }
</style>