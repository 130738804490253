<template>
    <div class="main-view">
        <div style="margin-left: 0;text-align: left;display: flex;align-items: center">
            <van-icon @click="back" name="arrow-left" size="20"/>
            <span style="margin-left: 20px;">礼包领取记录</span>
        </div>
        <div v-if="recordList.length === 0" style="background-color: #FFFFFF;margin-top: 20px;height: 80px;display: flex;justify-content: center;align-items: center;border-radius: 5px;">
            <span class="none-text">暂无领取记录</span>
        </div>

        <div style="margin-top: 20px;">
            <div v-for="(item,index) in recordList" style="margin-top: 1px;" class="record-list">
                <div @click="open(index)" style="display: flex">
                    <img :src="item.gameImgUrl" style="width: 38px;height: 38px;">
                    <div style="display: flex;flex-direction: column;align-items: flex-start;justify-content: space-between;margin-left: 15px;flex: 1">
                        <div style="display: flex;justify-content: space-between;align-items: center;width: 100%;padding-right: 30px;box-sizing: border-box">
                            <span style="font-size: 14px;font-weight: 700;color: #586EFC;">{{item.giftName}}</span>
                            <span v-if="item.status === 2" style="font-size: 14px;font-weight: bold;color: #666666">待审核</span>
                            <span v-if="item.status === 3" style="font-size: 14px;font-weight: bold;color: #666666">驳回</span>
                            <span v-if="item.status === 1" style="font-size: 14px;font-weight: bold;color: #666666">已发放</span>
                            <span v-if="item.status === 4" style="font-size: 14px;font-weight: bold;color: #666666">同组已领</span>
                        </div>
                        <span style="font-size: 13px;">{{ item.gameName }}</span>
                    </div>
                    <van-icon v-show="!item.open" name="arrow-down" color="#888888" />
                    <van-icon v-show="item.open" name="arrow-up" color="#888888" />
                </div>
                <div v-if="item.open" style="background-color: #FFFFFF;text-align: left;padding: 8px 25px;font-size: 13px;">
                    <div style="display: flex;justify-content: space-between">
                        <span style="color: #8E9BDE">区服</span>
                        <span style="color: #5152EC">{{item.serverName}}</span>
                    </div>
                    <div style="display: flex;justify-content: space-between;margin-top: 5px;">
                        <span style="color: #8E9BDE">角色</span>
                        <span style="color: #5152EC;">{{item.roleName}}</span>
                    </div>
                    <div style="display: flex;justify-content: space-between;margin-top: 5px;">
                        <span style="color: #8E9BDE">领取时间</span>
                        <span style="color: #5152EC">{{item.getTime}}</span>
                    </div>
                    <div v-if="!!item.giftCode" style="display: flex;justify-content: space-between;margin-top: 5px;">
                        <span style="color: #8E9BDE">礼包码</span>
                        <div style="width: 70%;text-align: right;overflow: auto">
                            <span style="color: #5152EC">{{item.giftCode}}</span>
                        </div>
                    </div>
                    <div v-if="!!item.checkResultMsg" style="display: flex;justify-content: space-between;margin-top: 5px;">
                        <span style="color: #8E9BDE">驳回原因</span>
                        <div style="max-width: 70%;text-align: right;word-break : break-all;overflow:hidden;">
                            <span style="color: #5152EC">{{item.checkResultMsg}}</span>
                        </div>
                    </div>
                    <van-button v-if="!!item.giftCode" @click="copyCode(item.giftCode)" size="mini" round color="#3DA7FF" style="padding: 0 15px;margin-top: 12px;">
                        <span style="font-size: 13px;font-weight: bold">复制礼包码</span>
                    </van-button>
                </div>
            </div>
        </div>

        <myloading v-show="showLoading"></myloading>

    </div>
</template>

<script>
    import {request} from '@/api/request'
    import {Toast} from 'vant'
    import myloading from '@/components/myloading'

    export default {
        name: "giftRecord",
        components: {
            myloading
        },
        data() {
            return {
                showLoading: false,
                recordList: [],
                isApp: '',
            }
        },
        mounted() {
            this.isApp = localStorage.getItem('isApp');
        },
        methods: {
            back() {
                this.$parent.$parent.closeGiftRecordPage();
            },
            init() {
                this.queryData();
            },
            copyCode(v) {
                if (this.isApp === '1') {
                    uni.postMessage({
                        data: {
                            action: 'uniCopy',
                            msg: v
                        }
                    });
                }
                else {
                    const input = document.createElement('input')
                    document.body.appendChild(input)
                    input.setAttribute('value', v)
                    input.select()
                    if (document.execCommand('copy')) {
                        document.execCommand('copy')
                    }
                    document.body.removeChild(input)
                    Toast('礼包码已经复制到剪切板');
                }
            },
            queryData() {
                this.showLoading = true;
                let options = {
                    url:"/zfapp/giftRecordList",
                    data: {}
                };
                request(options).then(res => {
                    this.showLoading = false;
                    if (res.data.success) {
                        let d = res.data.data;
                        d.forEach(function(e, i) {
                            e.open = false;
                        });
                        this.recordList = d;
                    }
                    else {
                        Toast(res.data.error.message);
                    }
                });
            },
            open(index) {
                this.recordList[index].open = !this.recordList[index].open;
            },
        }
    }
</script>

<style scoped>
    .main-view {
        width: 100vw;
        height: 100%;
        display: flex;
        flex-direction: column;
        background-color: #F5F6F8;
        overflow: hidden;
        overflow-y: auto;
        box-sizing: border-box;
        padding: 60px 20px 10px;
    }
    .none-text{
        font-size: 18px;
        color: #888888;
        text-align: center;
    }
    .record-list{
        background-color: #FFFFFF;
        border-radius: 5px;
        padding: 8px 10px;
    }
</style>