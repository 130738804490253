<template>
    <div class="login-main">
        <div style="display: flex;align-items: center;width: 100%;background-color: #6e8ec9;height: 80px;">
            <img src="../../static/main-round.png" class="logo" style="width: 50px;height: 50px;">
            <span style="font-size: 22px;font-weight: bold;color: #ffffff;margin-left: 20px;">七里网络</span>
        </div>

        <div class="form-view">
            <div class="logo-view">
                <span class="top-span">帐号注册</span>
            </div>
            <div class="ts-form">
                <van-field placeholder="请输入帐号" @focus="inputOnFocus(1)" @blur="inputBlur(1)" :style="usernameInputStyle" v-model="username" clearable></van-field>

                <van-field class="margin-10"  @focus="inputOnFocus(2)" @blur="inputBlur(2)" :style="passwordInputStyle" type="password"  placeholder="请输入密码" v-model="password" clearable></van-field>

                <van-field class="margin-10"  @focus="inputOnFocus(3)" @blur="inputBlur(3)" :style="password2InputStyle" type="password"  placeholder="请再次输入密码" v-model="password2" clearable></van-field>

                <div class="margin-10"  style="display: flex;justify-content: flex-start;align-items: center">
                    <van-checkbox v-model="agree" icon-size="15px" checked-color="#606CFD">
                        <span style="color: #333333;font-size: 13px;">已阅读并同意</span>
                    </van-checkbox>
                    <span style="color: #007AFF;font-size: 13px;" @click="showDialog = true">《七里游戏用户协议》</span>
                </div>

                <van-button color="#5A6EFC" class="main-button margin-10" block @click="submit" >注册</van-button>

                <div class="margin-10" style="display: flex;justify-content: flex-end">
                    <div style="color: #999999; font-size: 13px;">
                        已有帐号?<span style="color: #5A6EFC; margin-left: 6px;" @click="back">去登录</span>
                    </div>

                </div>

                <van-popup v-model="showDialog" position="center" :lock-scroll="false" style="background-color: transparent" :style="{ width: '90%', height: '70%' }" >
                    <div style="height: 100%;background-color: #FFFFFF;border-radius: 5px;overflow: hidden">
                        <div style="padding: 10px;height: calc(100% - 70px);">
                            <iframe v-show="true" allowfullscreen ref="dialogIframe"   style="margin:0;padding:0" :src="userAgreeUrl" frameborder="no" border="0"
                                    marginwidth="0"
                                    marginheight="0" scrolling="yes" width="100%" height="100%"></iframe>
                        </div>
                        <div @click="showDialog = false" style="height: 50px;display: flex;align-items: center;justify-content: center;border-top: 0.5px solid #DDDDDD">
                            <span style="font-size: 13px;color: #5A6EFC">关闭</span>
                        </div>
                    </div>
                </van-popup>

            </div>
        </div>
        <myloading v-show="showLoading"></myloading>
    </div>
</template>

<script>
    import {request} from '@/api/request'
    import crypto from '@/utils/crypto.js';
    import {Toast} from 'vant'
    import myloading from '@/components/myloading'

    export default {
        name: "login",
        components: {
            myloading
        },
        data() {
            return {
                showDialog: false,
                showLoading: false,
                focusInput: 'outline: 2px #3478F6 solid;',
                usernameInputStyle: '',
                passwordInputStyle: '',
                password2InputStyle: '',
                username: '',
                password: '',
                password2: '',
                agree: false,
                usernameRegexp: /^[a-zA-Z0-9]{6,12}$/,
                passwordRegexp: /^[a-zA-Z0-9~!@#$%^&*()_+.-=]{6,12}$/,
                userAgreeUrl: 'https://game.7li.cn/static/sdk/user.html',
            }
        },
        methods: {
            //https://game.7li.cn/static/sdk/user.html
            openUserAgreePage() {

            },
            inputOnFocus(e) {
                if (e == 1) {
                    this.usernameInputStyle = this.focusInput;
                }
                else if (e == 2) {
                    this.passwordInputStyle = this.focusInput;
                }
                else if (e == 3) {
                    this.password2InputStyle = this.focusInput;
                }
            },
            inputBlur(e) {
                if (e == 1) {
                    this.usernameInputStyle = '';
                }
                else if (e == 2) {
                    this.passwordInputStyle = '';
                }
                else if (e == 3) {
                    this.password2InputStyle = '';
                }
            },
            back() {
                this.$router.back();
            },
            submit() {
                let ok = this.checkForm();
                if (ok !== 'ok') {
                    Toast(ok);
                    return;
                }
                this.showLoading = true;
                let options = {
                    url:"/zfapp/register",
                    data: {
                        userName: this.username,
                        userPwd: crypto.encrypt(this.password)
                    }
                };
                request(options).then(res => {
                    this.showLoading = false;
                    console.log(res);
                    if (res.data.success) {
                        Toast('账号注册成功');
                        setTimeout(() => {
                            this.back();
                        }, 2000);
                    }
                    else {
                        Toast(res.data.error.message);
                    }
                });
            },
            checkForm() {
                if (!this.username) {
                    return "请输入七里帐号";
                }
                if (!this.usernameRegexp.test(this.username)) {
                    return "七里帐号为6-12位数字字母";
                }
                if (!this.password) {
                    return "请输入密码";
                }
                if (!this.passwordRegexp.test(this.password)) {
                    return "密码为6-12位数字字母特殊符号";
                }
                if (!this.password2) {
                    return "请再次输入密码";
                }
                if (this.password !== this.password2) {
                    return "两次密码输入不一致，请重新输入";
                }
                if (!this.agree) {
                    return '帐号注册需要同意七里游戏用户协议';
                }
                return "ok";
            },
        }
    }
</script>

<style scoped>
    .login-main {
        width: 100vw;
        height: 100vh;
        background-color: #f1f4f6;
        overflow: hidden;
    }

    .form-view{
        box-sizing: border-box;
        width: 90%;
        margin: 120px auto 0;
        max-width: 400px;
        min-width: 300px;
        background-color: rgba(255,255,255,0.9);
        border-radius: 5px;
        min-height: 20%;
        padding: 20px 27px 0;
    }
    .top-span{
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 10px;
    }
    .ts-form{
        padding-top: 10px;
        padding-bottom: 20px;
    }
    .logo {
        margin-left: 30px;
    }
    .logo-view{
        display: flex;
        align-items: flex-end;
    }
    .main-button{
        font-size: 16px;
        height: 40px;
    }

    .van-field{
        outline: 1px #dbdbdb solid;
        border-radius: 3px;
    }
    .van-field__control:focus{
        outline: 1px red solid !important;
        border-radius: 3px;
    }
</style>