<template>
    <div class="center">
        <div style="margin-left: -20px;display: flex;align-items: center">
            <van-icon @click="back" name="arrow-left" size="20"/>
            <span style="font-size: 16px;margin-left: 10px;">修改密码</span>
        </div>
        <div style="margin: 20px 0;background-color: #FFFAEF; border-radius: 5px ;padding: 8px;">
            <span style="font-size: 12px;color:#FBB610;">为了您的帐号安全，请定期修改帐号登录密码。</span>
        </div>
        <div class="menu-view">
            <van-field class="common-input" type="password" maxlength="12" v-model="password_1" clearable placeholder="请输入原密码"/>
            <van-field class="common-input" type="password" maxlength="12" v-model="password_2" clearable placeholder="请输入新密码"/>
            <van-field class="common-input" type="password" maxlength="12" v-model="password_3" clearable placeholder="请再次输入新密码"/>
        </div>
        <van-button color="#5A6EFC" class="main-button margin-10" block @click="submit">提交</van-button>

        <myloading v-show="showLoading"></myloading>

    </div>
</template>

<script>
    import {request} from '@/api/request'
    import crypto from '@/utils/crypto.js';
    import {Toast} from 'vant'
    import myloading from '@/components/myloading'

    export default {
        name: "password",
        components: {
            myloading
        },
        data() {
            return {
                showLoading: false,
                password_1: '',
                password_2: '',
                password_3: '',
                passwordRegexp: /^[a-zA-Z0-9~!@#$%^&*()_+.-=]{6,12}$/,
            }
        },
        methods: {
            back() {
                this.$parent.$parent.colsePasswordPage();
            },
            submit() {
                let ok = this.validatorInput();
                if (ok !== 'ok') {
                    this.topToast(ok);
                    return ;
                }
                this.showLoading = true;
                let options = {
                    url:"/zfapp/modifyPasswordByOld",
                    data: {
                        newUserPwd: crypto.encrypt(this.password_2),
                        userPwd: crypto.encrypt(this.password_1)
                    }
                };
                request(options).then(res => {
                    this.showLoading = false;
                    if (res.data.success) {
                        this.topToast('密码修改成功,请重新登录');
                        localStorage.removeItem('userInfo');
                        localStorage.removeItem('token');
                        localStorage.removeItem('userId');
                        this.password_1 = '';
                        this.password_2 = '';
                        this.password_3 = '';
                        this.back();
                        setTimeout(() => {
                            this.$router.replace("/login");
                        },2000);
                    }
                    else {
                        this.topToast(res.data.error.message);
                    }
                });
            },
            validatorInput() {
                if (!this.password_1) {
                    return "请输入原密码";
                }
                if (!this.password_2) {
                    return "请输入新密码";
                }
                if (!this.password_3) {
                    return "请再次输入新密码";
                }
                if(!this.passwordRegexp.test(this.password_1)) {
                    return "原密码为6-12位数字字母特殊符号";
                }
                if(!this.passwordRegexp.test(this.password_2)) {
                    return "新密码为6-12位数字字母特殊符号";
                }
                if(this.password_2 !== this.password_3) {
                    return "两次密码输入不一致，请重新输入";
                }
                if(this.password_1 === this.password_2) {
                    //return "新密码不能和旧密码相同";
                }
                return "ok";
            },
            topToast(m) {
                Toast({
                    message: m,
                    position: 'top',
                });
            }
        }
    }
</script>

<style scoped>
    .center {
        flex: 1;
        flex-direction: column;
        background-color: #F5F6F8;
        height: 100%;
        box-sizing: border-box;
        padding: 60px 40px 0;
        text-align: left;
    }
    .title-tip{
        font-size: 25px;
        margin-top: 10px;
        font-weight: 700;
    }
    .menu-view {
        margin: 30px auto 0;
        border-radius: 5px;
        background-color: #FFFFFF;
    }
    .main-button{
        font-size: 16px;
        height: 40px;
        border-radius: 5px;
        margin-top: 40px;
    }
    .common-input{
        background: transparent;
        height: 50px;
    }

</style>