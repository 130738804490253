<template>
    <div class="common-body-view">
        <div  class="common-title">
            <img @click="back" src='../../img/left.png' style="width: 20px; height: 20px;">
            <span class="common-title-text">游戏加速</span>
        </div>

        <div class="main">
            <div style="display: flex;justify-content: space-between;margin-top: 5px;padding: 0 10px;user-select: none">
                <div @click="setSpeed(0.2)" class="speed-button" :class="speedRate === 0.2 && 'speed-button-active'">
                    X0.2
                </div>
                <div @click="setSpeed(0.5)" class="speed-button" :class="speedRate === 0.5 && 'speed-button-active'">
                    X0.5
                </div>
                <div @click="setSpeed(1)" class="speed-button" :class="speedRate === 1 && 'speed-button-active'">
                    原速
                </div>
            </div>
            <div style="display: flex;justify-content: space-between;margin-top: 10px;padding: 0 10px;">
                <div @click="setSpeed(3)" class="speed-button" :class="speedRate === 3 && 'speed-button-active'">
                    X3
                </div>
                <div @click="setSpeed(5)" class="speed-button" :class="speedRate === 5 && 'speed-button-active'">
                    X5
                </div>
                <div @click="setSpeed(10)" class="speed-button" :class="speedRate === 10 && 'speed-button-active'">
                    X10
                </div>
            </div>
            <div style="display: flex;justify-content: space-between;margin-top: 10px;padding: 0 10px;">
                <div @click="setSpeed(15)" class="speed-button" :class="speedRate === 15 && 'speed-button-active'">
                    X15
                </div>
                <div @click="setSpeed(20)" class="speed-button" :class="speedRate === 20 && 'speed-button-active'">
                    X20
                </div>
                <div @click="setSpeed(32)" class="speed-button" :class="speedRate === 32 && 'speed-button-active'">
                    X32
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import {Toast} from 'vant'

    export default {
        name: 'kf',
        data() {
            return {
                speedRate: 1,
            };
        },
        mounted() {
            this.speedRate = parseInt(localStorage.getItem('speedRate') || '1');
        },
        methods: {
            back() {
                this.$parent.$parent.closeGameSpeedPage();
            },
            setSpeed(n) {
                localStorage.setItem('speedRate', n);
                this.speedRate = n;
                this.$parent.$parent.setSpeed(n);
            },
        }
    }
</script>

<style scoped>
    .main-container {
        overflow: hidden;
    }

    .dialog-title {
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: left;
        padding: 0 10px;
        width: 100%;
        vertical-align: center;
        font-size: 16px;
        color: #ED9015;
    }

    .speed-button {
        font-weight: bold;
        width: 50px;
        height: 50px;
        font-size: 14px;
        line-height: 50px;
        color: #666;
        background-color:  #ffffff;
        border: 2px solid #666;
        text-align: center;
        cursor: pointer;
    }
    .speed-button-active {
        color: #ffffff;
        border: 2px solid #e73d50;
        background-color: #e73d50;
    }
</style>
