<template>
    <div class="common-body-view">
        <div  class="common-title">
            <img @click="back" src='../../img/left.png' style="width: 20px; height: 20px;">
            <span class="common-title-text">交易平台</span>
        </div>

        <div class="main">


            <div style="color: #333333;text-align: left;font-size: 14px;margin-top: 10px;">
                如需使用天使服专属交易平台，请前往“七里网络”公众号，点击底部菜单:
            </div>
            <div style="color: #e35454;text-align: left;font-size: 14px;font-weight: bold;margin: 10px 20px;">
                服务-交易平台
            </div>

            <div style="color: #666666;text-align: left;font-size: 12px;margin-top: 10px;">
                七里网络交易平台，支持七里平台帐号、材料交易，无找回风险更安全，官方客服全程参与，让您无后顾之忧。
            </div>
            <div style="color: #666666;text-align: left;font-size: 12px;margin-top: 5px;">
                温馨提示：请勿私下交易帐号信息，以免被骗！！！
            </div>
            <van-button type="info" block native-type="submit" :loading="showLoading"
                        style="height: 35px;width: 120px;margin: 15px auto 0;"
                        loading-text=" "  @click="openWx"
                        color="#0f78e8">
                去微信
            </van-button>

        </div>

    </div>
</template>

<script>
    import '../../css/commonpage.css'
    import notice from '@/api/notice';

    export default {
        name: "jxf-trade",
        components: {
        },
        data() {
            return {
                showLoading: false,
            }
        },
        mounted() {
        },
        methods: {

            back() {
                this.$parent.$parent.closeTradePage();
            },
            openWx() {
                this.showLoading = true;
                setTimeout(() => {
                    this.showLoading = false;
                }, 2000);
                let url = 'weixin://';
                notice.jumpApp(url);
            }
        }
    }
</script>

<style scoped>


</style>