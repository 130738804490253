<template>
    <div></div>
</template>

<script>
    export default {
        name: "temp",
        mounted() {
            let pathSplit = this.$route.path.split('/');
            let channelId = pathSplit[2];
            let gameId = pathSplit[3];
            let systemInfo = {
                device : 'H5',
                systemVersion : 'H5',
                udid : 'WEB',
                channelId : channelId,
                gameId : gameId,
                sdkVersion: '200'
            }
            localStorage.setItem('isApp', '0');
            localStorage.setItem('systemInfo', JSON.stringify(systemInfo));
            console.log('channelId gameId:',channelId, gameId);
            let from = this.$route.query.from;
            localStorage.setItem('from', from);

            let login = this.$route.query.login;
            if (from === 'qiliApp') {
                let os = this.$route.query.os;
                localStorage.setItem('qiliapp_os', os);
                if (login === '1') {
                    let u = this.$route.query.u;
                    let p = this.$route.query.p.replace(' ', '+');
                    localStorage.setItem('qiliapp_username', u);
                    localStorage.setItem('qiliapp_password', p);
                    localStorage.setItem('qiliapp_login', login);
                }

            }
            localStorage.setItem('gameId', gameId);
            localStorage.setItem('gameSpeedSwitch', 'true');
            localStorage.setItem('login_status', 'ios');
            this.$router.replace('/login')
        }
    }
</script>

<style scoped>

</style>